const IcCardGrey = (props: React.SVGProps<SVGSVGElement>) => {
    return (
        <svg width="38" height="26" viewBox="0 0 38 26" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M37.2675 5.99139V4.04238C37.2675 1.80983 35.4577 0 33.2252 0H4.35098C2.11843 0 0.308594 1.80983 0.308594 4.04238V5.99139C0.308594 6.19069 0.470217 6.35232 0.669521 6.35232H36.9066C37.1059 6.35232 37.2675 6.19069 37.2675 5.99139Z"
                fill="#616161"
            />
            <path
                d="M0.308594 9.02499V21.3687C0.308594 23.6012 2.11843 25.4111 4.35098 25.4111H33.2252C35.4577 25.4111 37.2675 23.6012 37.2675 21.3687V9.02499C37.2675 8.82569 37.1059 8.66406 36.9066 8.66406H0.669521C0.470217 8.66406 0.308594 8.82569 0.308594 9.02499ZM9.54833 18.4813C9.54833 19.1191 9.03119 19.6362 8.39336 19.6362H7.2384C6.60056 19.6362 6.08343 19.1191 6.08343 18.4813V17.3263C6.08343 16.6885 6.60056 16.1713 7.2384 16.1713H8.39336C9.03119 16.1713 9.54833 16.6885 9.54833 17.3263V18.4813Z"
                fill="#616161"
            />
        </svg>
    )
}

export default IcCardGrey
