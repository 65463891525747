import { defineStyle, defineStyleConfig } from "@chakra-ui/react"

const primary = defineStyle({
    background: "secondary",
    color: "white",
    borderRadius: "02px",
    _loading: {
        bg: "secondary !important",
    },
})

const primaryOutline = defineStyle({
    background: "#F8FAFF",
    borderRadius: "02px",
    borderWidth: "1px",
    borderStyle: "solid",
    borderColor: "secondary",
    color: "secondary",
    _loading: {
        borderWidth: "1px",
        borderStyle: "solid",
        borderColor: "secondary",
        background: "#F8FAFF",
    },
    _hover: {
        background: "#DCE9FF",
    },
})

const grey = defineStyle({
    background: "#8A8A8A",
    color: "white",
    borderRadius: "02px",
    _loading: {
        bg: "#8A8A8A !important",
    },
})

const variants = {
    primary,
    grey,
    "primary-outline": primaryOutline,
}

const base = defineStyle({
    fontSize: { base: "14px", lg: "16px" },
    fontWeight: 700,
    p: { base: "8px 35px" },
})

const lg = defineStyle({
    width: "100%",
    fontSize: "16px",
    fontWeight: 600,
    px: "16px",
    h: "44px",
    border: "1px solid",
    borderColor: "transparent",
})

const xl = defineStyle({
    width: "100%",
    fontSize: "20px",
    fontWeight: 700,
    p: "0 10px",
    h: { base: "52px", lg: "60px" },
})

const sizes = {
    base,
    lg,
    xl,
}

export const buttonTheme = defineStyleConfig({
    variants,
    sizes,
    baseStyle: {
        WebkitTapHighlightColor: "transparent",
    },
})
