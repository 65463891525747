import { Box } from "@chakra-ui/react"
import BoxColumn from "@/components/atoms/BoxColumn/BoxColumn"
import remarkGfm from "remark-gfm"
import Markdown from "react-markdown"

import TaxiCgv from "./content/legals-taxi.md?raw"
import RestoCgv from "./content/legals-resto.md?raw"
import IciCgv from "./content/legals-ici.md?raw"
import { dynamicValue } from "@/utils/verticale.ts"
import "./markdown.css"
import TwContainer from "@/components/tailwind-components/atoms/TwContainer.tsx"

const TermsOfUse = () => {
    const isTaxi = import.meta.env.VITE_VERTICALE === "taxi"

    const DynamicContainer = isTaxi ? BoxColumn : TwContainer
    const dynamicContainerProps = isTaxi
        ? {
              mt: { base: "30px", lg: "40px" },
          }
        : {}
    return (
        <Box pb={{ base: "30px", lg: "60px" }} minH="70vh" className={"markdown"}>
            <DynamicContainer {...dynamicContainerProps}>
                <Box
                    // @ts-ignore
                    variant="base"
                    color="#616161"
                    textAlign="justify"
                    fontSize={{
                        base: "14px",
                        lg: "16px",
                    }}
                >
                    <Markdown remarkPlugins={[remarkGfm]}>
                        {dynamicValue({ taxi: TaxiCgv, resto: RestoCgv, ici: IciCgv })}
                    </Markdown>
                </Box>
            </DynamicContainer>
        </Box>
    )
}

export default TermsOfUse
