const IcPhoneCall = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 19 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M11 5C12.5 5 14 6.5 14 8M10 1C14 1 18 5 18 9M5.53333 13.4667C2.79542 10.7288 1.52735 8.207 1.1373 7.32869C1.04127 7.11244 1 6.87888 1 6.64226L1 4.82843C1 4.29799 1.21071 3.78929 1.58579 3.41421L2.58579 2.41421C3.36683 1.63317 4.63317 1.63317 5.41421 2.41421L6.84062 3.84062C7.51475 4.51475 7.61934 5.57099 7.09051 6.36424L6.64381 7.03429C6.24569 7.63146 6.19507 8.39826 6.56019 9.01616C6.94122 9.66096 7.47061 10.4706 8 11C8.52939 11.5294 9.33904 12.0588 9.98385 12.4398C10.6017 12.8049 11.3685 12.7543 11.9657 12.3562L12.6358 11.9095C13.429 11.3807 14.4852 11.4852 15.1594 12.1594L16.5858 13.5858C17.3668 14.3668 17.3668 15.6332 16.5858 16.4142L15.5858 17.4142C15.2107 17.7893 14.702 18 14.1716 18H12.3577C12.1211 18 11.8876 17.9587 11.6713 17.8627C10.793 17.4727 8.27124 16.2046 5.53333 13.4667Z"
      stroke="#09090B"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default IcPhoneCall;
