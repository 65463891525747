const IcVisaGrey = (props: React.SVGProps<SVGSVGElement>) => {
    return (
        <svg width="40" height="26" viewBox="0 0 40 26" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M38.3095 0H1.48671C0.934083 0 0.487305 0.447465 0.487305 0.999408V24.1597C0.487305 24.7116 0.934083 25.1591 1.48671 25.1591H38.3095C38.8621 25.1591 39.3089 24.7116 39.3089 24.1597V0.999408C39.3089 0.447465 38.8621 0 38.3095 0ZM3.49309 7.60279H7.63644C8.19526 7.6241 8.64548 7.80212 8.80082 8.39943L9.70193 12.7332C8.81663 10.4663 6.68309 8.5802 3.45872 7.79937L3.49309 7.60279ZM8.50663 17.4869L6.11327 9.31017C7.83371 10.4113 9.29915 12.1586 9.81535 13.3745L10.0944 14.3842L12.7118 7.69696H15.5437L11.3351 17.4848L8.50663 17.4869ZM17.625 17.4938H14.9526L16.6236 7.68665H19.2974L17.625 17.4938ZM22.2756 17.6409C21.0776 17.6285 19.9235 17.3914 19.298 17.1171L19.674 14.9224L20.0184 15.0784C20.8961 15.4448 21.4646 15.5932 22.5348 15.5932C23.3025 15.5932 24.1253 15.2929 24.1329 14.6358C24.1377 14.2062 23.7885 13.8996 22.7479 13.4185C21.7333 12.9497 20.3902 12.1634 20.4067 10.755C20.4212 8.84896 22.2825 7.51893 24.924 7.51893C25.9592 7.51893 26.7888 7.73201 27.3181 7.93066L26.9572 10.0566L26.7159 9.94322C26.2238 9.74526 25.5907 9.55418 24.7157 9.56793C23.6696 9.56793 23.1843 10.0037 23.1843 10.4113C23.1788 10.8705 23.7507 11.1729 24.6855 11.6279C26.2272 12.3276 26.94 13.1765 26.9304 14.2921C26.9098 16.3266 25.0862 17.6409 22.2756 17.6409ZM33.8575 17.4979C33.8575 17.4979 33.6135 16.3706 33.5324 16.0283C33.1434 16.0283 30.4159 16.0242 30.1094 16.0242C30.0056 16.2888 29.5478 17.4979 29.5478 17.4979H26.7414L30.7094 8.51078C30.9913 7.87223 31.469 7.69696 32.1096 7.69696H34.1744L36.3375 17.4972L33.8575 17.4979Z"
                fill="#616161"
            />
            <path
                d="M32.48 11.1892L32.2999 10.3438C32.1639 10.7129 31.9295 11.306 31.9446 11.2799C31.9446 11.2799 31.0998 13.4568 30.8799 14.0211H33.0993C32.9921 13.5296 32.48 11.1892 32.48 11.1892Z"
                fill="#616161"
            />
        </svg>
    )
}

export default IcVisaGrey
