import { twMerge } from 'tailwind-merge';

const IcBus = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="47"
      height="47"
      viewBox="10 10 40 40"
      fill="none"
      {...props}
      className={twMerge('text-secondary', props.className)}
    >
      <path
        d="M29.4128 11.7646C20.9628 11.7646 14.1187 12.7244 14.1187 19.4427V37.678C14.1187 41.3826 17.1201 44.3962 20.8098 44.3962L17.9422 47.2755V48.2352H40.8834V47.2755L38.0157 44.3962C41.7054 44.3962 44.7069 41.3826 44.7069 37.678V19.4427C44.7069 12.7244 37.8628 11.7646 29.4128 11.7646ZM20.8098 40.5572C19.2231 40.5572 17.9422 39.2712 17.9422 37.678C17.9422 36.0848 19.2231 34.7987 20.8098 34.7987C22.3966 34.7987 23.6775 36.0848 23.6775 37.678C23.6775 39.2712 22.3966 40.5572 20.8098 40.5572ZM27.501 29.0402H17.9422V19.4427H27.501V29.0402ZM38.0157 40.5572C36.4289 40.5572 35.1481 39.2712 35.1481 37.678C35.1481 36.0848 36.4289 34.7987 38.0157 34.7987C39.6025 34.7987 40.8834 36.0848 40.8834 37.678C40.8834 39.2712 39.6025 40.5572 38.0157 40.5572ZM40.8834 29.0402H31.3245V19.4427H40.8834V29.0402Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default IcBus;
