const IcGroup = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 20 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M1 21H19M13 1H7C3.69 1 3 1.69 3 5V21H17V5C17 1.69 16.31 1 13 1Z"
      stroke="black"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13 21V18C13 16.345 12.655 16 11 16H9C7.345 16 7 16.345 7 18V21M11.5 5H8.5M11.5 8.5H8.5M11.5 12H8.5"
      stroke="black"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default IcGroup;
