const IcAlternativesBg = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 34 35"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M17 34.5526C26.3888 34.5526 34 26.9415 34 17.5526C34 8.16377 26.3888 0.552612 17 0.552612C7.61116 0.552612 0 8.16377 0 17.5526C0 26.9415 7.61116 34.5526 17 34.5526Z"
      fill="currentColor"
    />
    <path
      d="M8 22.5526H11.0775C12.2926 22.5526 13.4419 22.0003 14.201 21.0514L19.799 14.0538C20.5581 13.105 21.7074 12.5526 22.9225 12.5526H26M26 12.5526L23 9.55261M26 12.5526L23 15.5526M8 12.5526H11.0775C12.2926 12.5526 13.4419 13.105 14.201 14.0538L19.799 21.0514C20.5581 22.0003 21.7074 22.5526 22.9225 22.5526H26M26 22.5526L23 25.5526M26 22.5526L23 19.5526"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default IcAlternativesBg;
