import TwIcon from "@/components/tailwind-components/atoms/Icons/TwIcon.tsx"
import TwText from "@/components/tailwind-components/atoms/TwText.tsx"
import { Link } from "@nextui-org/react"
import TwContainer from "@/components/tailwind-components/atoms/TwContainer.tsx"
import Logo from "@/components/molecules/Logo.tsx"
import { commonData } from "@/constants/config.ts"

export default function TwFooter() {
    const pageName = commonData.pageName

    const footers = [
        {
            name: pageName.howItsWork,
            href: "/fonctionnement",
        },
        {
            name: pageName.ourMission,
            href: "/notre-mission",
        },
        {
            name: pageName.ourPartner,
            href: "/notre-partenaire",
        },
    ]

    return (
        <footer className="bg-blue-900 text-white md:px-4">
            <TwContainer className="py-8">
                <div className="flex items-center justify-between border-blue-150 border-b-[0.5px] pb-4">
                    <Link href={"/"}>
                        <Logo variant={"2xl"} />
                    </Link>
                    <div className="flex gap-2">
                        <TwIcon name="ic-lock-base" className="mr-2" />
                        <TwIcon name="ic-master" />
                        <TwIcon name="ic-cb" />
                        <TwIcon name="ic-visa" />
                    </div>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-3 py-6 border-b-[0.5px] border-blue-150 gap-4">
                    <div className="flex flex-col gap-2 p-1 md:p-0">
                        <TwText variant="m" className="font-semibold text-white">
                            Entreprise
                        </TwText>
                        {footers.map((item) => (
                            <Link href={item.href} key={item.href}>
                                <TwText variant="s" className="text-white">
                                    {item.name}
                                </TwText>
                            </Link>
                        ))}
                    </div>
                    <div className="flex flex-col gap-2 p-1 md:p-0">
                        <TwText variant="m" className="font-semibold text-white">
                            Infos contact
                        </TwText>
                        <TwText variant="s" className=" text-white">
                            Horaires d’ouvertures : tous les jours de 7h à 22h
                        </TwText>
                        <Link href={"/faq"}>
                            <TwText variant="s" className="text-white">
                                FAQ
                            </TwText>
                        </Link>
                    </div>
                    <div className="flex flex-col gap-2 p-1 md:p-0">
                        <TwText variant="m" className="font-semibold text-white">
                            Légal
                        </TwText>
                        <Link href={"/confidentialite"}>
                            <TwText variant="s" className="text-white">
                                Charte de confidentialité
                            </TwText>
                        </Link>
                        <Link href={"/mentions-legales-et-cgu"}>
                            <TwText variant="s" className="text-white">
                                Mentions légales et conditions générales d'utilisation
                            </TwText>
                        </Link>
                    </div>
                </div>
                <div className="pt-4">
                    <TwText variant="xs" className="text-white">
                        Une demande de réservation n’est pas une confirmation. La prestation est garantie après
                        validation par l’établissement. Nous ne sommes affiliés à aucun établissement.
                    </TwText>
                </div>
            </TwContainer>
        </footer>
    )
}
