const IcPencil = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="25"
    height="25"
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_3169_27148)">
      <path
        d="M5.4375 20.6094H19.0375"
        stroke="#0D2542"
        strokeLinecap="round"
      />
      <path
        d="M17.6237 8.80397L18.2544 8.17497C18.5028 7.92666 18.6998 7.63187 18.8342 7.30741C18.9687 6.98296 19.0379 6.63521 19.0379 6.28401C19.038 5.93281 18.9688 5.58505 18.8345 5.26057C18.7001 4.93608 18.5032 4.64125 18.2548 4.39288C18.0065 4.14452 17.7117 3.9475 17.3873 3.81306C17.0628 3.67863 16.7151 3.60941 16.3639 3.60938C16.0127 3.60934 15.6649 3.67847 15.3404 3.81283C15.016 3.94719 14.7211 4.14415 14.4728 4.39246L13.8421 5.02316C13.8421 5.02316 13.9211 6.36276 15.1026 7.54427C16.2841 8.72577 17.6237 8.80482 17.6237 8.80482L11.8293 14.5993C11.4366 14.992 11.2411 15.1875 11.0243 15.3566C10.7687 15.5561 10.4942 15.7258 10.2007 15.8658C9.9533 15.9839 9.6898 16.0715 9.16365 16.2466L6.93154 16.9912M13.8421 5.02231L8.0476 10.8176C7.6549 11.2103 7.45854 11.4058 7.29024 11.6226C7.09088 11.8777 6.92013 12.1539 6.78109 12.4462C6.66294 12.6936 6.57539 12.9571 6.39944 13.4832L5.65569 15.7153M5.65569 15.7153L5.47464 16.2602C5.4322 16.3872 5.426 16.5236 5.45673 16.654C5.48746 16.7843 5.55391 16.9036 5.64862 16.9983C5.74333 17.093 5.86256 17.1594 5.99293 17.1902C6.1233 17.2209 6.25965 17.2147 6.38669 17.1722L6.93154 16.9912M5.65569 15.7153L6.93154 16.9912"
        stroke="#0D2542"
      />
    </g>
    <defs>
      <clipPath id="clip0_3169_27148">
        <rect
          width="24"
          height="24"
          fill="white"
          transform="translate(0.4375 0.609375)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default IcPencil;
