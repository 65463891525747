const IcBill = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M13 17H5C2.79086 17 1 15.2091 1 13V1L4 3L7 1L10 3L13 1V9M13 17V9M13 17V17C15.2091 17 17 15.2091 17 13V9H13M7 6H10M7 10H10M7 14H10M4 6H4.00898M4 10H4.00898M4 14H4.00898"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default IcBill;
