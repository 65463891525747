const IcSandClock = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="14"
    height="20"
    viewBox="0 0 14 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M2 1H12M2 1V5.17157C2 5.70201 2.21071 6.21071 2.58579 6.58579L4.58579 8.58579C5.36683 9.36683 5.36684 10.6332 4.58579 11.4142L2.58579 13.4142C2.21071 13.7893 2 14.298 2 14.8284V19M2 1H1M12 1V5.17157C12 5.70201 11.7893 6.21071 11.4142 6.58579L9.41421 8.58579C8.63316 9.36684 8.63317 10.6332 9.41421 11.4142L11.4142 13.4142C11.7893 13.7893 12 14.298 12 14.8284V19M12 1H13M12 19H2M12 19H13M2 19H1"
      stroke="#09090B"
      strokeWidth="1.3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default IcSandClock;
