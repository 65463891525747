import { getSubscriptionPrice } from "@/utils/price.ts"

export const homeData = {
    howItsWork: {
        title: "Comment ça marche ?",
        data: [
            {
                icon: "ic-search-2",
                content: "<strong>Recherchez un restaurant</strong> partout en France",
            },
            {
                icon: "ic-note",
                content: `<strong>Inscrivez-vous</strong> pour 3 jours gratuits, puis ${getSubscriptionPrice()}€/mois, annulable à tout moment`,
            },
            {
                icon: "ic-woment",
                content: "<strong>Un assistant de la conciergerie</strong> s'occupe de votre réservation",
            },
            {
                icon: "ic-phone-resto",
                content: "<strong>Vous recevez la confirmation</strong> sur votre téléphone",
            },
        ],
    } as {
        title: string
        data: { icon: string; content: string }[]
    },
    principalSpecific: {
        title: "Réservez votre restaurant en toute confiance",
        description:
            "Nous collaborons avec des conciergeries expérimentées pour gérer vos réservations de restaurants, offrant une annulation sans frais et une garantie de satisfaction. Nous simplifions uniquement la mise en relation entre vous et nos conciergeries partenaires, garantissant une expérience transparente et sans tracas.",
        infoBar:
            "Une fois votre demande soumise, elle ne garantit pas une réservation immédiate. La confirmation dépend de l'acceptation par le restaurant. Si la demande ne peut être honorée, nos concierges partenaires vous proposeront des alternatives valides.",
        image: "/images/illus-man-and-girl-drinking.jpeg",
        imageClasses: "bg-[position:40%]",
        advantages: [
            '<span class="text-blue-500">+160 000</span> restaurants',
            '<span class="text-blue-500">+3000 villes</span> en France',
            '<span class="text-blue-500">20 pays</span> en Europe',
            'Assistance <span class="text-blue-500">7j/7</span>',
        ],
    } as {
        title: string
        description: string
        image: string
        imageClasses: string
        infoBar: string
        advantages: string[]
    },
    principalGeneral: {
        title: "L’offre conciergerie",
        label: "Réservez tout ce dont vous avez besoin, sans effort",
        description:
            "Fini les recherches interminables pour réserver. Tout est centralisé et nos agents sont là pour gérer vos demandes de A à Z. Que vous ayez besoin d’un taxi, d’un restaurant, d’un coiffeur ou même d’un service à domicile comme un plombier ou un jardinier, on s'occupe de tout, où que vous soyez. Vous nous dites ce dont vous avez besoin, et nous nous chargeons de la réservation, en vous tenant informé à chaque étape.",
        advantages: [
            '<span class="text-blue-500">+1 500 000 services </span>',
            '<span class="text-blue-500">+60 000 villes</span> couvertes',
            '<span class="text-blue-500">130 catégories</span> de service',
            'Assistance <span class="text-blue-500">7j/7</span>',
        ],
        image: "/images/man-sitting-on-sofa.svg",
    } as {
        title: string
        label: string
        description: string
        advantages: string[]
        image: string
    },
    clickCard: {
        title: "Simplifiez vos réservations en un clic.",
        description:
            "Économisez du temps et simplifiez vos démarches. Votre assistant est là pour vous, peu importe vos besoins. Réservez en quelques clics et laissez-le gérer les détails.",
        buttonText: "Rechercher un restaurant",
        bulletPoints: [
            "<strong>Simple</strong> : Réservez en 2 clics, sans appel nécessaire.",
            "<strong>Rapide</strong> : Un agent traite chaque demdande dans la minute.",
            "<strong>Proactif</strong> : Si votre demande n'est aps disponible, un assistant trouve une alternative.",
        ],
        image: "/images/woman-smile-with-phone.jpeg",
    } as {
        title: string
        description: string
        buttonText: string
        bulletPoints: string[]
        image: string
    },
    assistantCard: {
        title: "Des assistants disponibles 24/7",
        description:
            "Les assistants de la conciergerie sont là pour vous aider à chaque étape de votre réservation, du début à la fin. Que ce soit pour dénicher le meilleur service ou gérer les imprévus, ils s'assurent que vous n'avez rien à faire. Vous êtes informé en temps réel par SMS, et ils sont joignables 7 jours sur 7.",
        image: "/images/man-smile-on-call.png",
    } as {
        title: string
        description: string
        image: string
    },
    whatsAppCard: {
        title: "Accédez à des assistants personnels via WhatsApp",
        description:
            "Simplifiez vos réservations grâce à l'assistant de la conciergerie, disponible 24h/24 et 7j/7 sur WhatsApp. Indiquez vos besoins, et ils s'occupent du reste, en vous tenant informé à chaque étape.",
        image: "/images/apple-devices.jpeg",
        buttonText: "En savoir plus",
    } as {
        title: string
        description: string
        image: string
        buttonText: string
    },
    promotionCard: {
        title: "Profitez d'une offre sans engagement",
        description: `<strong>3 jours d’essai gratuit puis seulement ${getSubscriptionPrice()} euros par mois</strong>, pour bénéficier d'une gestion complète de vos réservations. Inscrivez-vous en toute confiance : annulation possible à tout moment, satisfaction garantie.`,
        image: "/images/girl-on-phone.jpeg",
        bulletPoints: [
            "<strong>Période d’essai gratuit</strong> : Profitez du service de la conciergerie sans engagement initial.",
            "<strong>Flexibilité totale</strong> : Réservez ce dont vous avez besoin, sans contraintes.",
            "<strong>Garantie de satisfaction</strong> : Si le service ne répond pas à vos attentes, vous pouvez annuler à tout moment.",
        ],
        buttonText: "Rechercher un restaurant",
    } as {
        title: string
        description: string
        image: string
        bulletPoints: string[]
        buttonText: string
    },
    listServices: {
        title: "Nos assistants facilitent toutes vos réservations, où que vous soyez",
        images: [
            {
                image: "images/hairdresser.jpeg",
                name: "Coiffeur",
            },
            {
                image: "images/florist.jpeg",
                name: "Fleuriste",
            },
            {
                image: "images/transports.jpeg",
                name: "Transports",
            },
            {
                image: "images/moving.jpeg",
                name: "Déménageur",
            },
        ],
    } as {
        title: string
        images: {
            image: string
            name: string
        }[]
    },
}
