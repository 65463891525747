import { Outlet, useLocation } from "react-router-dom"
import Navbar from "@/components/organisms/Navbar/Navbar"
import Footer from "@/components/organisms/Footer/Footer"
import { useSession } from "@/contexts/SessionContext.tsx"
import { Suspense, useEffect, useMemo, useState } from "react"
import FallbackLoading from "@/pages/FallbackLoading.tsx"
import TwNavbar from "@/components/tailwind-components/organisms/TwNavbar.tsx"

export const NAVBAR_HEIGHT = { base: "65px", lg: "80px" }

const DefaultTemplate = () => {
    const { pathname } = useLocation()
    const session = useSession()

    const displayNavbar = useMemo(() => {
        return import.meta.env.VITE_VERTICALE === "taxi" && pathname !== "/paiement-confirmation"
    }, [pathname])

    const [isLoaded, setIsLoaded] = useState(false)

    useEffect(() => {
        if (pathname === "/") {
            session.createOrUpdateCookieAndState({
                ...session,
                parcours: "classic",
            })
        } else if (pathname === "/reserver-un-taxi" || pathname === "/reserver-un-restaurant") {
            session.createOrUpdateCookieAndState({
                ...session,
                parcours: "acquiz",
            })
        }

        setTimeout(() => {
            setIsLoaded(true)
        }, 10)
    }, [])

    return (
        <Suspense fallback={<FallbackLoading />}>
            {isLoaded && (
                <>
                    {displayNavbar && (
                        <Navbar
                            h={
                                pathname === "/reserver-un-taxi"
                                    ? {
                                          base: "65px",
                                          lg: "120px",
                                      }
                                    : NAVBAR_HEIGHT
                            }
                        />
                    )}

                    {import.meta.env.VITE_VERTICALE !== "taxi" && <TwNavbar />}

                    <Outlet />

                    <Footer />
                </>
            )}
        </Suspense>
    )
}

export default DefaultTemplate
