const IcStarNotice = (props: React.SVGProps<SVGSVGElement>) => (
    <svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <rect width="30" height="30" transform="translate(0.939453 0.605469)" fill="#328D78" />
        <path
            d="M15.9395 4.60547L18.4091 12.2063H26.4011L19.9354 16.9038L22.4051 24.5047L15.9395 19.8071L9.47382 24.5047L11.9435 16.9038L5.47783 12.2063H13.4698L15.9395 4.60547Z"
            fill="white"
        />
    </svg>
)

export default IcStarNotice
