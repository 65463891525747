import { Link, useLocation } from "react-router-dom"
import { Box, Flex, Heading, HStack, Image, Text } from "@chakra-ui/react"
import BoxColumn from "@/components/atoms/BoxColumn/BoxColumn"
import { CadenaIcon } from "@/theme/icons/Icons"
import paymentSecureIMG from "@/assets/images/cb.png"
import { dynamicValue } from "@/utils/verticale.ts"
import TwFooter from "@/components/tailwind-components/templates/TwFooter"

const Footer = () => {
    const location = useLocation()
    const isHomepage = location.pathname === "/" || location.pathname === "/paiement-confirmation"
    const isTaxi = import.meta.env.VITE_VERTICALE === "taxi"
    const isResto = import.meta.env.VITE_VERTICALE === "resto"
    const isIci = import.meta.env.VITE_VERTICALE === "ici"

    return !isTaxi ? (
        <TwFooter />
    ) : (
        <Box
            as="footer"
            bg="#FCFCFC"
            py={{ base: "30px" }}
            pb={isHomepage ? "180px" : "20px"}
            zIndex={200}
            position="relative"
            mt={"auto"}
        >
            <BoxColumn>
                <Flex direction={{ base: "column" }} borderBottom="1px solid #868686" pb={{ base: "7px", lg: "15px" }}>
                    <Flex
                        w="100%"
                        borderBottom="1px solid #868686"
                        pb={{ base: "6px", lg: "10px" }}
                        align="center"
                        justify="space-between"
                    >
                        <Heading as="h3" mt={{ base: "8px", lg: "unset" }} fontSize={{ base: "20px" }} fontWeight={700}>
                            {dynamicValue({
                                taxi: "Taxi Reza",
                                resto: "Resto Reza",
                                ici: "Reserve Ici",
                            })}
                        </Heading>
                        <HStack>
                            <CadenaIcon fill="black" w={{ base: "16px" }} h={{ base: "16px" }} />
                            <Image w={{ base: "100px" }} src={paymentSecureIMG} loading={"lazy"} />
                        </HStack>
                    </Flex>
                    <Flex
                        direction={{ base: "column", lg: "row" }}
                        justify={{ lg: "space-between" }}
                        gap={{ lg: "80px" }}
                    >
                        <Flex
                            mt={{ base: "10px", lg: "13px" }}
                            pb={{ base: "5px", lg: "0" }}
                            borderBottom={{ base: "1px solid #868686", lg: "none" }}
                            justify={{ base: "space-between", lg: "unset" }}
                            gap={{ lg: "80px" }}
                        >
                            <Flex
                                direction="column"
                                gap={{ base: "4px" }}
                                fontSize={{ base: "12px", lg: "14px" }}
                                pb={{ base: "6px", lg: "10px" }}
                            >
                                <Text decoration="underline" fontWeight="700">
                                    Légal
                                </Text>
                                {/* <Link to="/mentions-legales">Mentions légales</Link> */}
                                <Link
                                    to={isResto ? "/mentions-legales-et-cgu" : "/mentions-legales-et-cgv"}
                                    target="_blank"
                                >
                                    Mentions légales et conditions générales
                                    {isResto ? " d'utilisation" : " de vente"}
                                </Link>
                                <Link to="/confidentialite" target="_blank">
                                    Charte de confidentialité
                                </Link>
                            </Flex>
                            {isTaxi && (
                                <Flex
                                    direction="column"
                                    gap={{ base: "4px" }}
                                    fontSize={{ base: "12px", lg: "14px" }}
                                    pb={{ base: "6px", lg: "10px" }}
                                >
                                    <Text decoration="underline" fontWeight="700">
                                        Entreprise
                                    </Text>
                                    <Link to="/a-propos">A Propos</Link>
                                    <Link to="/carriere">Carrière</Link>
                                    <Link to="/engagements">Nos Engagements</Link>
                                    <Link to="/comment-ca-marche">Comment ça marche ?</Link>
                                    <Link to="/faq">FAQ</Link>
                                    {/*<Link to="/contact">Contact</Link>*/}
                                </Flex>
                            )}
                        </Flex>

                        <Box mt={{ base: "10px", lg: "13px" }} mr={{ lg: "50px" }}>
                            <Text as="i" variant="sm" fontWeight="300" fontSize={{ base: "11px", lg: "12px" }}>
                                Horaires d’ouverture du service client : du lundi au vendredi de 9h à 18h
                            </Text>
                            {/*{isTaxi && (*/}
                            {/*    <Link to={"/contact"}>*/}
                            {/*        <Box*/}
                            {/*            mt={{ base: "12px" }}*/}
                            {/*            bg="#2571FE"*/}
                            {/*            color="white"*/}
                            {/*            display={"flex"}*/}
                            {/*            justifyContent={"center"}*/}
                            {/*            alignItems={"center"}*/}
                            {/*            h={{ base: "41px" }}*/}
                            {/*            w={{ base: "156px" }}*/}
                            {/*            fontSize={{ base: "18px" }}*/}
                            {/*            fontWeight={700}*/}
                            {/*        >*/}
                            {/*            Contact*/}
                            {/*        </Box>*/}
                            {/*    </Link>*/}
                            {/*)}*/}
                        </Box>
                    </Flex>
                </Flex>

                {isTaxi && (
                    <Text
                        variant="extrasm"
                        color="black"
                        mt={{ base: "8px", lg: "15px" }}
                        fontSize={{ base: "12px", lg: "14px" }}
                    >
                        Taxi Reza n'est pas une centrale de taxi et n’agit qu’en{" "}
                        <Text as="strong">
                            qualité de comparateur et annuaire de taxi et vtc pour ses client. C’est le client qui
                            choisi le taxi souhaité et qui le contacte directement.
                        </Text>
                        <br />
                        La responsabilité contractuelle de Taxi Reza ne saurait être engagée pour une faute résultant de
                        la course ou de tout autre faute par le taxi ou le vtc.
                    </Text>
                )}

                {isResto && (
                    <Text
                        variant="extrasm"
                        color="black"
                        mt={{ base: "8px", lg: "15px" }}
                        fontSize={{ base: "12px", lg: "14px" }}
                    >
                        <Text as="strong">
                            Une demande de réservation n’est pas une confirmation. La prestation est garantie après
                            validation par l’établissement. Nous ne sommes affiliés à aucun établissement.
                        </Text>
                    </Text>
                )}

                {isIci && (
                    <Text
                        variant="extrasm"
                        color="black"
                        mt={{ base: "8px", lg: "15px" }}
                        fontSize={{ base: "12px", lg: "14px" }}
                    >
                        <Text as="strong">
                            Une demande de réservation n’est pas une confirmation. La prestation est garantie après
                            validation par l’établissement. Nous ne sommes affiliés à aucun établissement.
                        </Text>
                    </Text>
                )}
            </BoxColumn>
        </Box>
    )
}

export default Footer
