const IcCheckBg = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 34 35"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx="17" cy="17.5526" r="17" fill="currentColor" />
    <path
      d="M25.0281 11.8856L14.6392 22.2745L9.91699 17.5523"
      stroke="#FBFFFB"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default IcCheckBg;
