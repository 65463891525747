const IcPolygon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="6"
      height="5"
      viewBox="0 0 6 5"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M3.34617 4.4C3.19221 4.66667 2.80731 4.66667 2.65335 4.4L0.748091 1.1C0.59413 0.833333 0.78658 0.5 1.0945 0.5L4.90501 0.5C5.21293 0.5 5.40538 0.833333 5.25142 1.1L3.34617 4.4Z"
        fill="#6D6D6D"
      />
    </svg>
  );
};

export default IcPolygon;
