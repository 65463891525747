const IcPhoneBasic = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M5.53333 12.4667C2.79542 9.72876 1.52735 7.207 1.1373 6.32869C1.04127 6.11244 1 5.87888 1 5.64226V3.82843C1 3.29799 1.21071 2.78929 1.58579 2.41421L2.58579 1.41421C3.36683 0.633166 4.63317 0.633165 5.41421 1.41421L6.84062 2.84062C7.51475 3.51475 7.61934 4.57099 7.09051 5.36424L6.64381 6.03429C6.24569 6.63146 6.19507 7.39826 6.56019 8.01616C6.94122 8.66096 7.47061 9.47061 8 10C8.52939 10.5294 9.33904 11.0588 9.98385 11.4398C10.6017 11.8049 11.3685 11.7543 11.9657 11.3562L12.6358 10.9095C13.429 10.3807 14.4852 10.4852 15.1594 11.1594L16.5858 12.5858C17.3668 13.3668 17.3668 14.6332 16.5858 15.4142L15.5858 16.4142C15.2107 16.7893 14.702 17 14.1716 17H12.3577C12.1211 17 11.8876 16.9587 11.6713 16.8627C10.793 16.4727 8.27125 15.2046 5.53333 12.4667Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default IcPhoneBasic;
