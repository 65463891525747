const IcCb = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="30"
      height="21"
      viewBox="0 0 30 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_2086_1917)">
        <path
          d="M0.847656 0.723633H29.4221V20.2763H0.847656V0.723633Z"
          fill="#29ABE2"
        />
        <path
          d="M0.847656 0.723633H29.4221V20.2763H0.847656V0.723633Z"
          fill="url(#paint0_radial_2086_1917)"
        />
        <path
          d="M0.847656 0.723633H29.4221V20.2763H0.847656V0.723633Z"
          fill="url(#paint1_radial_2086_1917)"
        />
        <path
          d="M16.382 4.88001H24.014C24.7212 4.88001 25.3994 5.1602 25.8994 5.65894C26.3995 6.15767 26.6804 6.83411 26.6804 7.53943C26.6804 8.24475 26.3995 8.92118 25.8994 9.41992C25.3994 9.91866 24.7212 10.1988 24.014 10.1988H16.382V4.88001ZM16.382 10.8018H24.014C24.7212 10.8018 25.3994 11.082 25.8994 11.5807C26.3995 12.0795 26.6804 12.7559 26.6804 13.4612C26.6804 14.1665 26.3995 14.843 25.8994 15.3417C25.3994 15.8404 24.7212 16.1206 24.014 16.1206H16.382V10.8018ZM9.73221 10.8018V10.1988H15.8746C15.7194 8.70005 14.9982 7.31631 13.8575 6.3285C12.7167 5.34069 11.2418 4.82282 9.73221 4.88001C8.17119 4.81577 6.64825 5.37119 5.49698 6.42461C4.3457 7.47804 3.65992 8.9436 3.58984 10.5003C3.65992 12.057 4.3457 13.5226 5.49698 14.576C6.64825 15.6294 8.17119 16.1849 9.73221 16.1206C11.2418 16.1778 12.7167 15.6599 13.8575 14.6721C14.9982 13.6843 15.7194 12.3006 15.8746 10.8018H9.73221Z"
          fill="white"
        />
      </g>
      <defs>
        <radialGradient
          id="paint0_radial_2086_1917"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(2.43453 19.7528) scale(28.9631 27.1543)"
        >
          <stop offset="0.09" stopColor="#009245" />
          <stop offset="0.23" stopColor="#049552" stopOpacity="0.89" />
          <stop offset="0.52" stopColor="#0D9E74" stopOpacity="0.59" />
          <stop offset="0.91" stopColor="#1BACAB" stopOpacity="0.12" />
          <stop offset="1" stopColor="#1FB0B8" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint1_radial_2086_1917"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(7.20593 21.4283) scale(37.1565 37.0596)"
        >
          <stop offset="0.15" stopColor="#1FB0B8" stopOpacity="0" />
          <stop offset="0.35" stopColor="#1C7491" stopOpacity="0.4" />
          <stop offset="0.56" stopColor="#1A4471" stopOpacity="0.73" />
          <stop offset="0.74" stopColor="#18265E" stopOpacity="0.93" />
          <stop offset="0.87" stopColor="#181B57" />
        </radialGradient>
        <clipPath id="clip0_2086_1917">
          <rect
            width="28.5769"
            height="19.5526"
            fill="white"
            transform="translate(0.84668 0.723633)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default IcCb;
