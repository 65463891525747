// IcWhatsapp

import { twMerge } from 'tailwind-merge';

const IcWhatsapp = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="35"
      height="36"
      viewBox="0 0 35 36"
      fill="none"
      {...props}
      className={twMerge('text-white fill-secondary', props.className)}
    >
      <path
        d="M3.67969 31.7891L5.62733 24.6788C4.42578 22.5981 3.79391 20.2369 3.79464 17.8186C3.79825 10.2529 9.95495 4.09766 17.5214 4.09766C21.1933 4.0991 24.6389 5.52839 27.2307 8.12163C29.8218 10.7156 31.2489 14.1627 31.2475 17.8295C31.2446 25.3952 25.0864 31.5512 17.5214 31.5512H17.5156C15.2188 31.5505 12.961 30.9743 10.9562 29.8804L3.67969 31.7891Z"
        fill="currentColor"
      />
      <path
        d="M3.66976 32.1507C3.57433 32.1507 3.48179 32.1131 3.41311 32.0437C3.32274 31.9519 3.28731 31.8181 3.32129 31.6945L5.22917 24.7281C4.04642 22.6272 3.4225 20.2414 3.42395 17.8195C3.42684 10.0535 9.7462 3.73633 17.5115 3.73633C21.2781 3.73777 24.8169 5.20465 27.4767 7.86658C30.1365 10.5292 31.6004 14.0674 31.599 17.8296C31.5961 25.5949 25.276 31.9128 17.5115 31.9128C15.206 31.9121 12.925 31.3431 10.9008 30.2666L3.76157 32.1384C3.73121 32.1471 3.70084 32.1507 3.66976 32.1507Z"
        fill="currentColor"
      />
      <path
        d="M17.5181 4.096C21.19 4.09745 24.6356 5.52673 27.2274 8.11998C29.8185 10.7139 31.2456 14.161 31.2441 17.8278C31.2412 25.3936 25.0831 31.5495 17.5181 31.5495H17.5123C15.2155 31.5488 12.9577 30.9726 10.9529 29.8788L3.67638 31.7874L5.62402 24.6771C4.42247 22.5964 3.79061 20.2353 3.79133 17.817C3.79494 10.2513 9.95164 4.096 17.5181 4.096ZM17.5181 3.37305C9.55401 3.37305 3.07199 9.85218 3.06837 17.817C3.06765 20.2512 3.68216 22.65 4.84757 24.7733L2.97873 31.5973C2.91077 31.8467 2.98017 32.1127 3.16236 32.2964C3.29972 32.4352 3.48552 32.5111 3.67638 32.5111C3.73783 32.5111 3.79928 32.5031 3.86001 32.4872L10.8633 30.6509C12.9078 31.7122 15.1996 32.2725 17.5123 32.2732C25.4822 32.2732 31.9642 25.7934 31.9678 17.8286C31.9693 13.9687 30.4677 10.3395 27.74 7.60957C25.0108 4.87896 21.3809 3.37449 17.5181 3.37305Z"
        fill="currentColor"
      />
      <path
        d="M25.6024 9.75846C23.448 7.6026 20.5843 6.41479 17.5364 6.41406C11.2437 6.41406 6.12594 11.5297 6.12305 17.818C6.12232 19.9731 6.72527 22.0711 7.86754 23.8879L8.13937 24.3195L6.98626 28.5278L11.3045 27.3957L11.7216 27.6429C13.4726 28.6826 15.481 29.232 17.5284 29.2327H17.5327C23.821 29.2327 28.9388 24.1164 28.941 17.8274C28.9417 14.7801 27.7568 11.9143 25.6024 9.75846Z"
        fill="fillColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.0818 12.0832C13.8251 11.5121 13.5547 11.5005 13.3096 11.4904C13.1094 11.4817 12.8809 11.4824 12.6525 11.4824C12.424 11.4824 12.0524 11.5685 11.7379 11.9119C11.4235 12.2553 10.5371 13.0845 10.5371 14.7719C10.5371 16.4593 11.7661 18.0903 11.9375 18.3187C12.1088 18.5472 14.3102 22.1207 17.7963 23.4958C20.6932 24.6381 21.2831 24.4111 21.9121 24.3539C22.5411 24.2968 23.9414 23.5247 24.227 22.7237C24.5126 21.9226 24.5126 21.2366 24.4273 21.0934C24.3412 20.9503 24.1128 20.865 23.7701 20.6929C23.4274 20.5208 21.7408 19.6916 21.4263 19.5774C21.1118 19.4631 20.8833 19.406 20.6542 19.7494C20.4257 20.0921 19.7685 20.865 19.5683 21.0934C19.368 21.3226 19.1678 21.3515 18.8251 21.1794C18.4824 21.0074 17.3777 20.6459 16.067 19.4776C15.0476 18.5689 14.3594 17.4461 14.1591 17.1027C13.9589 16.76 14.1374 16.5742 14.3095 16.4029C14.4635 16.2489 14.6522 16.0024 14.8242 15.8021C14.9956 15.6018 15.0527 15.4587 15.1669 15.2302C15.2811 15.0011 15.224 14.8008 15.138 14.6295C15.0534 14.4574 14.3868 12.7613 14.0818 12.0832Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default IcWhatsapp;
