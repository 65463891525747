import { howItsWorkData } from "@/constants/local/ici/howItsWork.ts"
import { ourMissionData } from "@/constants/local/ici/ourMission.ts"
import { ourPartnerData } from "@/constants/local/ici/ourPartner.ts"
import { faqData } from "@/constants/local/ici/faqData.ts"
import { homeData } from "@/constants/local/ici/homeData.ts"

export const staticIciData = {
    pages: {
        howItWorks: howItsWorkData,
        ourMission: ourMissionData,
        ourPartner: ourPartnerData,
        faq: faqData,
        home: homeData,
    },
}
