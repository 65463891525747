export const ourMissionData = {
    introduce: {
        title: "Notre mission",
        description:
            "Notre but est de vous donner accès aux meilleurs restaurants, que ce soit localement ou à l'étranger. Nous nous engageons à assurer que chaque repas soit une expérience mémorable grâce à nos partenaires fiables et dévoués, qui s'efforcent de vous offrir une qualité et satisfaction exceptionnelles.",
    },
    missions: [
        {
            title: "Engagement envers la qualité",
            icon: "ic-diamond",
            description:
                "Nous nous engageons à fournir un service de réservation de restaurants de haute qualité, en assurant que chaque établissement partenaires réponde à nos standards élevés.",
        },
        {
            title: "Accessibilité et simplicité",
            icon: "ic-mobility",
            description:
                "Nous nous engageons à rendre accessible les meilleures expériences culinaires partout en France, en toute sécurité. Nous nous occupons de trouver l'emplacement idéal qui correspond à vos attentes.",
        },
        {
            title: "Expérience client exceptionnelle",
            icon: "ic-customer-service",
            description:
                "Notre service client est là pour vous aider avec vos réservations de restaurants, disponible tous les jours de 7h à 22h. Nous répondons rapidement pour garantir une assistance personnalisée.",
        },
        {
            title: "Offre de conciergerie garantie satisfait ou remboursé",
            icon: "ic-shield",
            description:
                "Pour la réservation de restaurant, Resto Reza vous redirige vers un service de conciergerie. Avec la conciergerie, vous profitez d’un assistant personnel avec 3 jours d’essai gratuit, puis 50 euros par mois, pour toutes vos réservations au quotidien. Votre assistant pourra procéder à la réservation de votre restaurant. C’est une offre annulable à tout moment et garantie satisfait ou remboursé.",
        },
    ],
    ourValues: {
        title: "Nos valeurs",
        class: { title: "!text-blue-500", itemBox: "md:grid-cols-5" },
        items: [
            {
                icon: "ic-search",
                title: "Transparence",
            },
            {
                icon: "ic-listening",
                title: "Ecoute",
            },
            {
                icon: "ic-diamond",
                title: "Qualité",
            },
            {
                icon: "ic-real-time",
                title: "Efficacité",
            },
            {
                icon: "ic-schedule",
                title: "Disponibilité",
            },
        ],
    },
}
