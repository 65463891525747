const IcStart = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="20"
    height="16"
    viewBox="0 0 20 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M17.8651 4.67393L17.8651 4.67395L17.8695 4.67114C18.259 4.42185 18.5 3.99529 18.5 3.53C18.5 2.46102 17.3346 1.83357 16.4384 2.38391L16.4384 2.3839L16.4349 2.38607L10 6.41028L3.56511 2.38607L3.56512 2.38606L3.56163 2.38391C2.66535 1.83357 1.5 2.46102 1.5 3.53C1.5 3.99529 1.74097 4.42185 2.13047 4.67114L2.13046 4.67116L2.13488 4.67393L8.67325 8.7629C8.67353 8.76307 8.6738 8.76325 8.67408 8.76342C9.48665 9.27553 10.5134 9.27553 11.3259 8.76342C11.3262 8.76325 11.3265 8.76307 11.3268 8.7629L17.8651 4.67393ZM2 0.5H18C18.8239 0.5 19.5 1.17614 19.5 2V14C19.5 14.8239 18.8239 15.5 18 15.5H2C1.17614 15.5 0.5 14.8239 0.5 14V2C0.5 1.17614 1.17614 0.5 2 0.5Z"
      fill="#EFF5FF"
      stroke="white"
    />
  </svg>
);

export default IcStart;
