const IcEuro = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M6.12602 11C6.57006 12.7252 8.13616 14 10 14C11.1065 14 12.1081 13.5507 12.8322 12.8246M6.12602 11C6.04375 10.6804 6 10.3453 6 10C6 9.6547 6.04375 9.31962 6.12602 9M6.12602 11H5M6.12602 11H9M6.12602 9C6.57006 7.27477 8.13616 6 10 6C11.0896 6 12.0775 6.43569 12.7989 7.14237M6.12602 9H5M6.12602 9H9M19 10C19 14.9706 14.9706 19 10 19C5.02944 19 1 14.9706 1 10C1 5.02944 5.02944 1 10 1C14.9706 1 19 5.02944 19 10Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default IcEuro;
