const IcArrowDown = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M21.7735 4.74462C21.703 4.68789 21.6209 4.64849 21.5334 4.6294C21.4459 4.61031 21.3554 4.61203 21.2686 4.63445L15.2059 6.19705L9.12745 3.06376C9.00176 2.99913 8.85779 2.983 8.72157 3.01828L2.44706 4.63546C2.31969 4.66792 2.20651 4.74335 2.1255 4.84976C2.04449 4.95616 2.00032 5.08743 2 5.2227V19.7773C1.99999 19.8695 2.02038 19.9605 2.05962 20.0434C2.09885 20.1262 2.15589 20.1988 2.22641 20.2555C2.29692 20.3122 2.37905 20.3516 2.46655 20.3707C2.55406 20.3897 2.64462 20.388 2.73137 20.3656L8.79412 18.803L14.8725 21.9362C14.9982 22.0009 15.1422 22.017 15.2784 21.9817L21.5529 20.3645C21.6803 20.3321 21.7935 20.2566 21.8745 20.1502C21.9555 20.0438 21.9997 19.9126 22 19.7773V5.2227C22 5.1305 21.9796 5.03953 21.9403 4.95668C21.9011 4.87384 21.844 4.80132 21.7735 4.74462ZM9.45098 4.58694L14.549 7.21486V20.4131L9.45098 17.7851V4.58694ZM3.17647 5.69875L8.27451 4.38479V17.6871L3.17647 19.0011V5.69875ZM20.8235 19.3043L15.7255 20.6182V7.3129L20.8235 5.99894V19.3043Z"
      fill="black"
    />
  </svg>
);

export default IcArrowDown;
