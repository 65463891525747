const IcTrain = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M32 31.9994H0L5.37328 21.1699H26.6267L32 31.9994Z"
      fill="#86A7AC"
    />
    <path
      d="M29.6907 12.381C29.6907 19.2185 26.6025 24.7615 22.7907 24.7615H9.21C5.40105 24.7615 2.31006 19.2165 2.31006 12.381C2.31006 5.543 5.3982 0 9.21 0H22.7913C26.6003 0 29.6907 5.545 29.6907 12.381Z"
      fill="#D1D2D3"
    />
    <path
      d="M3.10718 6.62844C2.60209 8.34794 2.31445 10.3034 2.31445 12.3809C2.31445 19.2184 5.40316 24.7614 9.2144 24.7614H22.7957C24.5421 24.7614 26.1344 23.5854 27.3494 21.6624C22.6485 20.2344 8.5889 21.0724 3.10946 6.62744"
      fill="#BCBDBF"
    />
    <path
      d="M29.9512 12.8746C29.9512 19.9636 27.0068 22.3031 23.0569 22.3031H19.0505C24.6275 15.5586 29.9512 11.7731 29.9512 12.8746ZM2.05859 12.8746C2.05859 19.9636 5.00348 22.3031 8.95283 22.3031H12.9593C7.38051 15.5586 2.05859 11.7731 2.05859 12.8746Z"
      fill="#F0F1F2"
    />
    <path
      d="M5.42458 5.38496C5.84691 4.93296 13.9591 4.48096 14.2416 5.38496C14.5241 6.28896 15.4144 11.466 14.2416 11.712C13.0682 11.959 5.98788 10.479 5.42458 9.32946C4.86243 8.17846 5.0034 5.83646 5.42458 5.38496ZM26.5724 5.38496C26.1501 4.93296 18.038 4.48096 17.756 5.38496C17.4741 6.28896 16.5826 11.466 17.756 11.712C18.9294 11.959 26.0097 10.479 26.5724 9.32946C27.1352 8.17846 26.9948 5.83646 26.5724 5.38496Z"
      fill="#66B1DE"
    />
    <path
      d="M29.0653 31.9994H2.94141L7.32849 23.1599H24.6777L29.0653 31.9994Z"
      fill="#63797B"
    />
    <path
      d="M4.03496 25.2749H27.4274V27.5804H4.03496V25.2749ZM32 31.9994H0L1.04098 29.9024H30.9579L32 31.9994Z"
      fill="#86A7AC"
    />
    <path
      d="M29.4606 14.3005C29.4606 21.138 26.6201 23.3955 22.8111 23.3955H18.948C24.3275 16.8905 29.4606 13.2415 29.4606 14.3005ZM2.56274 14.3005C2.56274 21.138 5.40376 23.3955 9.21272 23.3955H13.0759C7.69576 16.8905 2.56274 13.2415 2.56274 14.3005Z"
      fill="#1181A8"
    />
    <path
      d="M2.56274 15.6956C2.56274 22.5336 5.40376 24.7906 9.21272 24.7906H13.0759C7.69576 18.2856 2.56274 14.6356 2.56274 15.6956ZM29.4606 15.6956C29.4606 22.5336 26.6201 24.7906 22.8111 24.7906H18.948C24.3275 18.2856 29.4606 14.6356 29.4606 15.6956Z"
      fill="#29ABE2"
    />
    <path
      d="M26.4472 24.5099C26.4472 26.3129 20.1065 29.7544 16.0014 29.7544C11.8951 29.7544 5.55786 26.3129 5.55786 24.5099C5.55786 22.7069 11.8928 22.0559 16.0014 22.0559C20.1077 22.0559 26.4472 22.7069 26.4472 24.5099Z"
      fill="#1181A8"
    />
    <path
      d="M26.1154 24.1399C26.1154 25.8859 19.9779 29.2194 16.0029 29.2194C12.0267 29.2194 5.89209 25.8859 5.89209 24.1399C5.89209 22.3949 12.0273 21.7654 16.0029 21.7654C19.9779 21.7654 26.1154 22.3949 26.1154 24.1399Z"
      fill="#29ABE2"
    />
    <path
      d="M27.9277 20.766C27.9277 21.57 27.3827 22.303 26.7087 22.303H24.2723C23.5983 22.303 23.0533 21.57 23.0533 20.766C23.0533 19.9595 23.5983 19.229 24.2723 19.229H26.7087C27.3821 19.229 27.9277 19.9595 27.9277 20.766ZM8.90809 20.766C8.90809 21.57 8.36305 22.303 7.68961 22.303H5.25323C4.57921 22.303 4.03418 21.57 4.03418 20.766C4.03418 19.9595 4.57921 19.229 5.25323 19.229H7.68961C8.36363 19.229 8.90809 19.9595 8.90809 20.766Z"
      fill="#FBED21"
    />
  </svg>
);

export default IcTrain;
