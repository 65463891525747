const IcRestoResult = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="19"
    height="19"
    viewBox="0 0 19 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M5.81039 10.8008L0.488507 16.1227C-0.162836 16.774 -0.162836 17.8339 0.488507 18.4852C1.10727 19.104 2.17769 19.1586 2.85107 18.4852L8.17299 13.1633L5.81039 10.8008Z"
      fill="#0D2542"
    />
    <path
      d="M18.1874 3.15014L15.1685 6.16898L14.3809 5.38144L17.3998 2.36256L16.6123 1.57503L13.5935 4.59387L12.8059 3.80634L15.8248 0.787497L15.0373 0L11.0996 3.93767C10.6179 4.41939 10.3303 5.059 10.2896 5.73877C10.2793 5.91099 10.2403 6.0804 10.1755 6.24153L12.7334 8.79944C12.8946 8.73461 13.064 8.6956 13.2362 8.68532C13.916 8.64476 14.5556 8.35705 15.0373 7.87534L18.975 3.93771L18.1874 3.15014Z"
      fill="#0D2542"
    />
    <path
      d="M17.4431 16.1547L9.99284 8.70436L1.28848 0H0.608225L0.469325 0.352947C0.102722 1.28454 -0.0539168 2.23736 0.0164054 3.10828C0.0965615 4.10103 0.473704 4.96051 1.10712 5.59393L7.90221 12.389L8.79848 11.4928L15.0805 18.5173C15.7054 19.1422 16.7811 19.1793 17.4431 18.5173C18.0945 17.8659 18.0945 16.806 17.4431 16.1547Z"
      fill="#0D2542"
    />
  </svg>
);

export default IcRestoResult;
