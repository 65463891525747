const IcCanceledBg = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 34 35"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_2353_95836)">
      <path
        d="M17 34.5526C26.3888 34.5526 34 26.9415 34 17.5526C34 8.16377 26.3888 0.552612 17 0.552612C7.61116 0.552612 0 8.16377 0 17.5526C0 26.9415 7.61116 34.5526 17 34.5526Z"
        fill="currentColor"
      />
      <g clipPath="url(#clip1_2353_95836)">
        <path
          d="M24.71 10.1344L9.58203 25.2624"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9.58203 10.1344L24.71 25.2624"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_2353_95836">
        <rect
          width="34"
          height="34"
          fill="white"
          transform="translate(0 0.552612)"
        />
      </clipPath>
      <clipPath id="clip1_2353_95836">
        <rect
          width="16.2917"
          height="16.2917"
          fill="white"
          transform="translate(9 9.55261)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default IcCanceledBg;
