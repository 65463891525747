import { twMerge } from 'tailwind-merge';

const IcStarFill = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="23"
      height="20"
      viewBox="0 0 23 20"
      fill="none"
      {...props}
      className={twMerge('text-yellow-300', props.className)}
    >
      <path
        d="M22.3337 7.63893L14.4609 7.14261L11.496 0L8.5311 7.14261L0.666992 7.63893L6.6987 12.5501L4.71942 20L11.496 15.8925L18.2726 20L16.2934 12.5501L22.3337 7.63893Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default IcStarFill;
