import { createMultiStyleConfigHelpers, defineStyle } from "@chakra-ui/react"
import { selectAnatomy } from "@chakra-ui/anatomy"

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(selectAnatomy.keys)

const baseStyle = definePartsStyle({
    field: {
        borderRadius: "3px",
        backgroundColor: "white",
        color: "black",
        h: "auto",
    },
    icon: {
        flexShrink: 0,
        w: "22px",
    },
})

const variants = {
    base: baseStyle,
    custom: definePartsStyle({
        field: {
            borderRadius: "3px",
            backgroundColor: "white",
            color: "black",
        },
        icon: {
            flexShrink: 0,
            color: "black",
            boxSize: "22px",
            svg: {
                boxSize: "22px !important",
            },
        },
    }),
}

const md = defineStyle({
    px: "14px",
    h: { base: "32px", lg: "62px" },
    fontSize: { base: "16px", lg: "16px" },
})

const xl = defineStyle({
    px: "16px",
    h: { base: "52px", lg: "62px" },
    fontSize: "16px",
})

const sizes = {
    md: definePartsStyle({ field: md, addon: md }),
    xl: definePartsStyle({ field: xl, addon: xl }),
}

export const selectTheme = defineMultiStyleConfig({ variants, sizes })
