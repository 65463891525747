import { useRedirectUrl } from "@/contexts/SessionContext.tsx"

export const useAssistanceTime = (): number => {
    const redirectUrl = useRedirectUrl()

    if (redirectUrl.includes("servi-life") || redirectUrl.includes("easy-rsv")) {
        return 5
    }
    // CT
    else {
        return 7
    }
}
