import { ChakraProvider } from "@chakra-ui/react"
import { css, Global } from "@emotion/react"
import { theme } from "@/theme/theme"
import Router from "@/router/Router.tsx"
import "@/assets/css/global.css"
import "@/assets/css/tailwind_base.css"
import { ReservationProvider } from "./contexts/ReservationContext"
import { useJsApiLoader } from "@react-google-maps/api"
import { SessionProvider } from "@/contexts/SessionContext"
import "react-day-picker/dist/style.css"
import "@/components/molecules/TaxiForm/parts/DatesModal.css"
import { GmapsContextProvider } from "@/contexts/GmapsContext.tsx"
import FallbackLoading from "@/pages/FallbackLoading.tsx"

const App = () => {
    const { isLoaded } = useJsApiLoader({
        googleMapsApiKey:
            import.meta.env.VITE_VERTICALE === "taxi"
                ? import.meta.env.VITE_GOOGLE_API_KEY_TAXI
                : import.meta.env.VITE_VERTICALE === "resto"
                ? import.meta.env.VITE_GOOGLE_API_KEY_RESTO
                : import.meta.env.VITE_GOOGLE_API_KEY_ICI,
    })

    // const [isReady, setIsReady] = useState(false)

    // const reloadIFPeristed = (event: { persisted: boolean }) => {
    //     console.log("reloadIFPeristed", event)
    //
    //     if (event.persisted) {
    //         window.location.reload()
    //     } else {
    //         setIsReady(true)
    //     }
    // }
    //
    // useEffect(() => {
    //     window.addEventListener("pageshow", reloadIFPeristed)
    //
    //     return () => {
    //         window.removeEventListener("pageshow", reloadIFPeristed)
    //     }
    // }, [])

    return isLoaded ? (
        <SessionProvider>
            <ReservationProvider>
                <ChakraProvider
                    // @ts-ignore
                    CSSReset
                    theme={theme}
                >
                    <Global
                        styles={css`
                            ::-webkit-tap-highlight-color {
                                background-color: transparent !important;
                            }

                            html {
                                scroll-behavior: smooth;
                                --scroll-behavior: smooth;
                            }
                        `}
                    />
                    <GmapsContextProvider>
                        <Router />
                    </GmapsContextProvider>
                </ChakraProvider>
            </ReservationProvider>
        </SessionProvider>
    ) : (
        <FallbackLoading />
    )
}

export default App
