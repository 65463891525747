import { twMerge } from 'tailwind-merge';

const IcQuotationMarks = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="26"
      height="23"
      viewBox="0 0 26 23"
      fill="none"
      {...props}
      className={twMerge('text-secondary', props.className)}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.28483 3.30155C9.96028 2.76147 10.0726 1.77292 9.53555 1.09358C8.99853 0.414233 8.01562 0.301326 7.34012 0.841425C1.64798 5.39255 0.5 11.3089 0.5 17.7857C0.5 20.3894 2.59866 22.5 5.18748 22.5C7.7763 22.5 9.87497 20.3894 9.87497 17.7857C9.87497 15.1822 7.7763 13.0714 5.18748 13.0714C4.738 13.0714 4.3033 13.1351 3.8918 13.2539C4.43155 9.27992 5.90214 6.00618 9.28483 3.30155ZM25.4999 17.7857C25.4999 20.3894 23.4013 22.5 20.8124 22.5C18.2235 22.5 16.1249 20.3894 16.1249 17.7857C16.1249 11.3089 17.2729 5.39255 22.9651 0.841425C23.6406 0.301326 24.6235 0.414233 25.1605 1.09358C25.6976 1.77292 25.5852 2.76147 24.9098 3.30155C21.5271 6.00618 20.0565 9.27992 19.5168 13.2539C19.9282 13.1351 20.3629 13.0714 20.8124 13.0714C23.4013 13.0714 25.4999 15.1822 25.4999 17.7857Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default IcQuotationMarks;
