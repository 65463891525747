const IcFlag = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="30"
      height="24"
      viewBox="0 0 30 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M29.9998 20.3077C29.9998 21.287 29.6486 22.2261 29.0234 22.9185C28.3983 23.611 27.5505 24 26.6664 24H19.9998V0H26.6664C27.5505 0 28.3983 0.38901 29.0234 1.08145C29.6486 1.77389 29.9998 2.71305 29.9998 3.69231V20.3077Z"
        fill="#ED2939"
      />
      <path
        d="M3.33333 0C2.44928 0 1.60143 0.38901 0.976311 1.08145C0.35119 1.77389 0 2.71305 0 3.69231L0 20.3077C0 21.287 0.35119 22.2261 0.976311 22.9185C1.60143 23.611 2.44928 24 3.33333 24H10V0H3.33333Z"
        fill="#002495"
      />
      <path d="M10.0002 0H20.0002V24H10.0002V0Z" fill="white" />
    </svg>
  );
};

export default IcFlag;
