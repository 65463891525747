const IcVisa = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="29"
      height="20"
      viewBox="0 0 29 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M28.5152 19.2758H0.423828V0.0390625H29.0008V19.2758H28.5152Z"
        fill="#1D5B99"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28.5151 18.7911H0.90918V0.525391H28.5151V18.7911Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.8871 12.623L12.6799 6.71162H14.2796L12.4866 12.623H10.8871ZM10.172 6.71325L8.73294 9.21927C8.36676 9.87569 8.15245 10.2072 8.04952 10.6217H8.02757C8.0531 10.0962 7.97977 9.45049 7.97294 9.08561L7.81391 6.71325H5.12123L5.09375 6.87227C5.78546 6.87227 6.1957 7.21975 6.30855 7.93065L6.83343 12.623H8.49066L11.8417 6.71325H10.172ZM22.612 12.623L22.5679 11.744L20.5707 11.7424L20.1619 12.623H18.4253L21.5744 6.72284H23.706L24.2391 12.623H22.612ZM22.4287 9.13602C22.4107 8.69911 22.396 8.10626 22.4255 7.74724H22.4019C22.3047 8.04073 21.8864 8.92203 21.7025 9.35537L21.1074 10.651H22.5099L22.4287 9.13602ZM15.959 12.791C14.8297 12.791 14.0801 12.4328 13.5451 12.1133L14.3071 10.9497C14.7876 11.2183 15.1646 11.5284 16.0326 11.5284C16.3115 11.5284 16.5804 11.456 16.7333 11.1915C16.9555 10.8072 16.6819 10.6001 16.0572 10.2464L15.7486 10.0458C14.8222 9.41293 14.4214 8.81163 14.8575 7.76219C15.1367 7.09097 15.8725 6.58203 17.0869 6.58203C17.924 6.58203 18.7092 6.94431 19.1664 7.29813L18.2905 8.32545C17.844 7.96447 17.4741 7.78187 17.0507 7.78187C16.7131 7.78187 16.4565 7.91211 16.3679 8.08772C16.2012 8.4178 16.4219 8.64284 16.9087 8.94496L17.2755 9.17845C18.4004 9.88789 18.6681 10.6316 18.3864 11.3267C17.9012 12.524 16.952 12.791 15.959 12.791ZM24.7154 7.51276H24.6461V7.09097H24.8061C24.9081 7.09097 24.9598 7.12658 24.9598 7.21228C24.9598 7.2887 24.9131 7.32041 24.8499 7.3274L24.9681 7.51276H24.8897L24.7808 7.3326H24.7154V7.51276ZM24.7928 7.27325C24.8448 7.27325 24.8907 7.26821 24.8907 7.20707C24.8907 7.15618 24.8407 7.15 24.7993 7.15H24.7154V7.27325H24.7928ZM24.7908 7.67585C24.5716 7.67585 24.4157 7.51179 24.4157 7.29471C24.4157 7.06561 24.5887 6.91374 24.7908 6.91374C24.9903 6.91374 25.1637 7.06561 25.1637 7.29471C25.1637 7.52398 24.9903 7.67585 24.7908 7.67585ZM24.7908 6.97992C24.6256 6.97992 24.4973 7.10935 24.4973 7.29471C24.4973 7.46788 24.6094 7.60951 24.7908 7.60951C24.9538 7.60951 25.0821 7.48106 25.0821 7.29471C25.0821 7.10935 24.9538 6.97992 24.7908 6.97992Z"
        fill="#1D5B99"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.8356 14.459H1.6377V17.9642H27.8356V14.459Z"
        fill="#E7A83A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.7866 1.39844H1.58887V4.90381H27.7866V1.39844ZM27.1879 17.7155H27.1186V17.2937H27.2786C27.3806 17.2937 27.4323 17.3293 27.4323 17.415C27.4323 17.4913 27.3856 17.523 27.3224 17.53L27.4406 17.7155H27.3622L27.2533 17.5352H27.1879V17.7155ZM27.2655 17.476C27.3175 17.476 27.3634 17.471 27.3634 17.4097C27.3634 17.3589 27.3134 17.3528 27.272 17.3528H27.1881V17.476H27.2655ZM27.2635 17.8784C27.0443 17.8784 26.8884 17.7145 26.8884 17.4973C26.8884 17.2684 27.0617 17.1165 27.2635 17.1165C27.4634 17.1165 27.6364 17.2684 27.6364 17.4973C27.6364 17.7266 27.4634 17.8784 27.2635 17.8784ZM27.2635 17.1825C27.0983 17.1825 26.97 17.3121 26.97 17.4973C26.97 17.6706 27.0821 17.8121 27.2635 17.8121C27.4264 17.8121 27.5547 17.6838 27.5547 17.4973C27.5547 17.3121 27.4264 17.1825 27.2635 17.1825Z"
        fill="#1D5B99"
      />
    </svg>
  );
};

export default IcVisa;
