import { inputAnatomy } from "@chakra-ui/anatomy"
import { createMultiStyleConfigHelpers, defineStyle } from "@chakra-ui/react"

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(inputAnatomy.keys)

const baseStyle = definePartsStyle({
    field: {
        borderRadius: "3px",
        backgroundColor: "white",
        color: "black",
    },
})

const blueStyle = definePartsStyle({
    field: {
        borderRadius: "2px",
        backgroundColor: "white",
        color: "black",
        border: "2px solid var(--chakra-colors-secondary)",
        _focus: {
            border: "2px solid var(--chakra-colors-secondary)",
        },
    },
})

const variants = {
    base: baseStyle,
    blue: blueStyle,
}

const md = defineStyle({
    px: "14px",
    h: { base: "32px", lg: "62px" },
    fontSize: { base: "14px", lg: "16px" },
})

const lg = defineStyle({
    px: "16px",
    h: { base: "48px", lg: "58px" },
    fontSize: "16px",
})

const xl = defineStyle({
    px: "16px",
    h: { base: "52px", lg: "62px" },
    fontSize: "16px",
})

const sizes = {
    md: definePartsStyle({ field: md, addon: md }),
    lg: definePartsStyle({ field: lg, addon: lg }),
    xl: definePartsStyle({ field: xl, addon: xl }),
}

export const inputTheme = defineMultiStyleConfig({ variants, sizes })
