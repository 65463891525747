const IcArrowUp = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 14 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M13 7L7.32308 1.14427C7.28164 1.09873 7.23159 1.06243 7.17603 1.03761C7.12046 1.0128 7.06055 0.999999 7 0.999999C6.93945 0.999999 6.87954 1.0128 6.82397 1.03761C6.76841 1.06243 6.71836 1.09873 6.67692 1.14427L1 7"
      stroke="#0D2542"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default IcArrowUp;
