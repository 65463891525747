const IcLockBase = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="17"
      height="21"
      viewBox="0 0 17 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7.66347 0.757595C5.46934 1.06403 3.57031 2.49976 2.51286 4.64768C2.04015 5.60105 1.72866 6.83532 1.68494 7.92204L1.67947 8.0781L0.898 8.08661C0.143852 8.09229 0.113795 8.09512 0.0646113 8.15187C0.0126953 8.21146 0.0126953 8.36468 0.0126953 14.1558L0.0126953 20.1002L0.0782734 20.1541C0.141119 20.208 0.31053 20.208 8.45588 20.208C16.6012 20.208 16.7706 20.208 16.8335 20.1541L16.8991 20.1002L16.8991 14.153C16.8991 8.48669 16.8963 8.20578 16.8499 8.15755C16.8062 8.11215 16.7215 8.10364 16.0165 8.09229L15.2323 8.0781L15.2268 7.92204C15.123 5.33717 13.7677 2.91118 11.7239 1.64854C11.0407 1.22577 10.3112 0.947701 9.4942 0.797318C9.07614 0.720707 8.08427 0.698009 7.66347 0.757595ZM9.13898 4.19086C9.83302 4.34691 10.4505 4.70159 10.956 5.22651C11.6391 5.9387 12.0408 6.84667 12.1446 7.91353L12.161 8.09229L8.45588 8.09229L4.75071 8.09229L4.76711 7.92772C4.80536 7.5702 4.84088 7.3574 4.90373 7.11338C5.1961 5.98977 5.93112 5.02505 6.87381 4.52567C7.25634 4.32421 7.60883 4.20788 8.07334 4.13694C8.23728 4.11141 8.95318 4.14829 9.13898 4.19086ZM8.94498 11.0829C9.3931 11.2957 9.6882 11.7327 9.75378 12.2746C9.81116 12.7428 9.66634 13.1883 9.33572 13.5628L9.15264 13.7671L9.15264 15.3844L9.15264 17.0017L8.44221 17.0017L7.73179 17.0017L7.73179 15.3759L7.73179 13.7529L7.55964 13.5656C7.26181 13.2393 7.13065 12.9045 7.13065 12.4619C7.13065 12.0363 7.25908 11.6901 7.52412 11.3922C7.89846 10.9723 8.46134 10.8502 8.94498 11.0829Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default IcLockBase;
