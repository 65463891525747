const IcEye = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="20"
    height="16"
    viewBox="0 0 20 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.24782 8.81793C1.05675 8.2862 1.05675 7.7138 1.24782 7.18207C1.91159 5.33484 4.098 1 10 1C15.902 1 18.0884 5.33485 18.7522 7.18207C18.9433 7.7138 18.9433 8.2862 18.7522 8.81793C18.0884 10.6652 15.902 15 10 15C4.098 15 1.91159 10.6652 1.24782 8.81793ZM13 8C13 9.65685 11.6569 11 10 11C8.34315 11 7 9.65685 7 8C7 6.34315 8.34315 5 10 5C11.6569 5 13 6.34315 13 8Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default IcEye;
