import { twMerge } from "tailwind-merge"

type Props = {
    children: React.ReactNode
    className?: string
    as?: keyof JSX.IntrinsicElements
}

const TwContainer = ({ children, className, as = "div" }: Props) => {
    const Element = as
    return <Element className={twMerge("max-w-[77.5rem] mx-auto px-4", className)}>{children}</Element>
}

export default TwContainer
