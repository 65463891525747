const IcSearch3 = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_1_6452)">
        <path
          d="M10.5048 18.7146C14.6495 18.7146 18.0095 15.3546 18.0095 11.2098C18.0095 7.06508 14.6495 3.70508 10.5048 3.70508C6.36 3.70508 3 7.06508 3 11.2098C3 15.3546 6.36 18.7146 10.5048 18.7146Z"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M21.2022 21.7061L16.0098 16.5137"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1_6452">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(0 0.705078)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default IcSearch3;
