const IcFork = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_28_3995)">
        <path
          d="M8.81039 12.8018L3.48851 18.1236C2.83716 18.775 2.83716 19.8349 3.48851 20.4862C4.10727 21.105 5.17769 21.1596 5.85107 20.4862L11.173 15.1643L8.81039 12.8018Z"
          fill="currentColor"
        />
        <path
          d="M21.1876 5.15014L18.1688 8.16898L17.3812 7.38144L20.4001 4.36256L19.6125 3.57503L16.5937 6.59387L15.8062 5.80634L18.825 2.7875L18.0375 2L14.0999 5.93767C13.6182 6.41939 13.3305 7.059 13.2899 7.73877C13.2796 7.91099 13.2405 8.0804 13.1758 8.24153L15.7337 10.7994C15.8948 10.7346 16.0643 10.6956 16.2364 10.6853C16.9162 10.6448 17.5558 10.3571 18.0375 9.87534L21.9752 5.93771L21.1876 5.15014Z"
          fill="currentColor"
        />
        <path
          d="M20.4431 18.1547L12.9928 10.7044L4.28848 2H3.60822L3.46932 2.35295C3.10272 3.28454 2.94608 4.23736 3.01641 5.10828C3.09656 6.10103 3.4737 6.96051 4.10712 7.59393L10.9022 14.389L11.7985 13.4928L18.0805 20.5173C18.7054 21.1422 19.7811 21.1793 20.4431 20.5173C21.0945 19.8659 21.0945 18.806 20.4431 18.1547Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_28_3995">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default IcFork;
