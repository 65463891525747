const IcCBGrey = (props: React.SVGProps<SVGSVGElement>) => {
    return (
        <svg width="38" height="26" viewBox="0 0 38 26" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path d="M0.267578 0H37.0354V25.1591H0.267578V0Z" fill="#616161" />
            <path
                d="M20.255 5.3502H30.0755C30.9854 5.3502 31.8581 5.71073 32.5015 6.35247C33.145 6.99422 33.5064 7.86461 33.5064 8.77217C33.5064 9.67973 33.145 10.5501 32.5015 11.1919C31.8581 11.8336 30.9854 12.1941 30.0755 12.1941H20.255V5.3502ZM20.255 12.97H30.0755C30.9854 12.97 31.8581 13.3305 32.5015 13.9722C33.145 14.614 33.5064 15.4844 33.5064 16.3919C33.5064 17.2995 33.145 18.1699 32.5015 18.8116C31.8581 19.4534 30.9854 19.8139 30.0755 19.8139H20.255V12.97ZM11.6985 12.97V12.1941H19.6022C19.4025 10.2656 18.4745 8.48508 17.0067 7.21402C15.5388 5.94297 13.641 5.27661 11.6985 5.3502C9.68991 5.26754 7.73029 5.98221 6.2489 7.3377C4.76751 8.69318 3.88509 10.579 3.79492 12.5821C3.88509 14.5851 4.76751 16.4709 6.2489 17.8264C7.73029 19.1819 9.68991 19.8966 11.6985 19.8139C13.641 19.8875 15.5388 19.2211 17.0067 17.9501C18.4745 16.679 19.4025 14.8985 19.6022 12.97H11.6985Z"
                fill="white"
            />
        </svg>
    )
}

export default IcCBGrey
