import { twMerge } from 'tailwind-merge';

const IcHourglass = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="34"
      height="34"
      viewBox="0 0 34 34"
      fill="none"
      {...props}
      className={twMerge('fill-orange-500', props.className)}
    >
      <path
        d="M17 34C26.3888 34 34 26.3888 34 17C34 7.61116 26.3888 0 17 0C7.61116 0 0 7.61116 0 17C0 26.3888 7.61116 34 17 34Z"
        fill="fillColor"
      />
      <path
        d="M24.1719 6.64062C24.1719 7.225 23.6938 7.70312 23.1094 7.70312H10.8906C10.3062 7.70312 9.82812 7.225 9.82812 6.64062V6.10938C9.82812 5.525 10.3062 5.04688 10.8906 5.04688H23.1094C23.6938 5.04688 24.1719 5.525 24.1719 6.10938V6.64062Z"
        fill="#263740"
      />
      <path
        d="M23.1094 5.04688H22.0469C22.6313 5.04688 23.1094 5.525 23.1094 6.10938V6.64062C23.1094 7.225 22.6313 7.70312 22.0469 7.70312H23.1094C23.6938 7.70312 24.1719 7.225 24.1719 6.64062V6.10938C24.1719 5.525 23.6938 5.04688 23.1094 5.04688Z"
        fill="#1E2C33"
      />
      <path
        d="M24.1719 27.8906C24.1719 28.475 23.6938 28.9531 23.1094 28.9531H10.8906C10.3062 28.9531 9.82812 28.475 9.82812 27.8906V27.3594C9.82812 26.775 10.3062 26.2969 10.8906 26.2969H23.1094C23.6938 26.2969 24.1719 26.775 24.1719 27.3594V27.8906Z"
        fill="#263740"
      />
      <path
        d="M23.1094 26.2969H22.0469C22.6313 26.2969 23.1094 26.775 23.1094 27.3594V27.8906C23.1094 28.475 22.6313 28.9531 22.0469 28.9531H23.1094C23.6938 28.9531 24.1719 28.475 24.1719 27.8906V27.3594C24.1719 26.775 23.6938 26.2969 23.1094 26.2969Z"
        fill="#1E2C33"
      />
      <path
        d="M17.7969 15.4062H16.2031V18.0625H17.7969V15.4062Z"
        fill="#E6E6E6"
      />
      <path
        d="M11.6875 7.70312V10.3594C11.6875 13.2935 14.0659 16.7344 17 16.7344C19.9341 16.7344 22.3125 13.2935 22.3125 10.3594V7.70312H11.6875Z"
        fill="#E6E6E6"
      />
      <path
        d="M21.2502 7.70312V10.625C21.2502 13.0531 19.6208 15.8257 17.3965 16.711C20.1454 16.4199 22.3127 13.1599 22.3127 10.3594V7.70312H21.2502Z"
        fill="#CCCCCC"
      />
      <path
        d="M11.6875 26.2969V23.6406C11.6875 20.7065 14.0659 17.2656 17 17.2656C19.9341 17.2656 22.3125 20.7065 22.3125 23.6406V26.2969H11.6875Z"
        fill="#E6E6E6"
      />
      <path
        d="M17.3965 17.2891C19.6208 18.1744 21.2502 20.947 21.2502 23.3751V26.2969H22.3127V23.6407C22.3127 20.8402 20.1454 17.5802 17.3965 17.2891Z"
        fill="#CCCCCC"
      />
      <path
        d="M17.2656 15.4062H16.7344V19.125H17.2656V15.4062Z"
        fill="#CCCCCC"
      />
      <path
        d="M17 19.125C14.7111 19.125 12.75 22.0461 12.75 24.4375V26.2969H21.25V24.4375C21.25 22.0461 19.2889 19.125 17 19.125Z"
        fill="#CCCCCC"
      />
      <path
        d="M13.0771 12.1546C13.7274 14.0023 15.2696 15.6717 16.9999 15.6717C18.378 15.6717 19.6357 14.6132 20.4198 13.2492C18.2826 13.1308 15.1828 11.8481 13.0771 12.1546Z"
        fill="#CCCCCC"
      />
    </svg>
  );
};

export default IcHourglass;
