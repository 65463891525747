const IcPCJdssGrey = (props: React.SVGProps<SVGSVGElement>) => {
    return (
        <svg width="67" height="26" viewBox="0 0 67 26" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M45.0944 14.3378L45.7543 14.5357C45.7103 14.7227 45.6443 14.8767 45.5453 14.9976C45.4463 15.1186 45.3363 15.2176 45.1933 15.2726C45.0504 15.3386 44.8744 15.3716 44.6544 15.3716C44.3905 15.3716 44.1815 15.3386 44.0055 15.2616C43.8406 15.1846 43.6976 15.0526 43.5766 14.8547C43.4556 14.6677 43.3896 14.4147 43.3896 14.1178C43.3896 13.7219 43.4996 13.4139 43.7086 13.1939C43.9176 12.974 44.2255 12.875 44.6104 12.875C44.9184 12.875 45.1494 12.941 45.3253 13.062C45.5013 13.1829 45.6333 13.3699 45.7103 13.6339L45.0504 13.7769C45.0284 13.6999 45.0064 13.6449 44.9734 13.6119C44.9294 13.5569 44.8854 13.5129 44.8194 13.4799C44.7534 13.4469 44.6874 13.4359 44.6214 13.4359C44.4565 13.4359 44.3245 13.5019 44.2365 13.6449C44.1705 13.7439 44.1375 13.9088 44.1375 14.1178C44.1375 14.3818 44.1815 14.5687 44.2585 14.6677C44.3355 14.7667 44.4565 14.8217 44.5994 14.8217C44.7424 14.8217 44.8524 14.7777 44.9184 14.7007C45.0064 14.6017 45.0614 14.4917 45.0944 14.3378Z"
                fill="#616161"
            />
            <path
                d="M46.0186 14.121C46.0186 13.725 46.1285 13.4171 46.3485 13.1971C46.5685 12.9772 46.8764 12.8672 47.2723 12.8672C47.6793 12.8672 47.9872 12.9772 48.2072 13.1861C48.4272 13.4061 48.5371 13.703 48.5371 14.099C48.5371 14.3849 48.4931 14.6159 48.3942 14.7919C48.2952 14.9678 48.1632 15.1108 47.9762 15.2098C47.8003 15.3088 47.5693 15.3638 47.3053 15.3638C47.0304 15.3638 46.8104 15.3198 46.6235 15.2318C46.4475 15.1438 46.3045 15.0118 46.1835 14.8139C46.0625 14.6159 46.0186 14.4069 46.0186 14.121ZM46.7664 14.121C46.7664 14.3629 46.8104 14.5389 46.8984 14.6489C46.9864 14.7589 47.1074 14.8139 47.2723 14.8139C47.4373 14.8139 47.5583 14.7589 47.6463 14.6599C47.7343 14.5499 47.7783 14.3629 47.7783 14.099C47.7783 13.868 47.7343 13.703 47.6353 13.6041C47.5473 13.4941 47.4153 13.4501 47.2613 13.4501C47.1074 13.4501 46.9864 13.5051 46.8984 13.6151C46.8104 13.7031 46.7664 13.879 46.7664 14.121Z"
                fill="#616161"
            />
            <path
                d="M48.9214 12.9141H49.9002L50.2742 14.3878L50.6481 12.9141H51.627V15.3337H51.0111V13.486L50.5381 15.3337H49.9882L49.5153 13.486V15.3337H48.8994V12.9141H48.9214Z"
                fill="#616161"
            />
            <path
                d="M52.1113 12.9141H53.3541C53.6291 12.9141 53.827 12.9801 53.959 13.112C54.091 13.244 54.157 13.42 54.157 13.6619C54.157 13.9039 54.08 14.1019 53.937 14.2338C53.794 14.3768 53.5631 14.4428 53.2661 14.4428H52.8592V15.3447H52.1113V12.9141ZM52.8592 13.9479H53.0462C53.1891 13.9479 53.2881 13.9259 53.3541 13.8709C53.4091 13.8159 53.4421 13.7609 53.4421 13.6839C53.4421 13.6069 53.4201 13.541 53.3651 13.486C53.3101 13.431 53.2221 13.409 53.0792 13.409H52.8702V13.9479H52.8592Z"
                fill="#616161"
            />
            <path d="M54.5527 12.9141H55.3006V14.7398H56.4664V15.3337H54.5527V12.9141Z" fill="#616161" />
            <path d="M56.8398 12.9141H57.5877V15.3337H56.8398V12.9141Z" fill="#616161" />
            <path
                d="M59.6125 14.9221H58.7656L58.6446 15.318H57.8857L58.7986 12.8984H59.6125L60.5253 15.318H59.7444L59.6125 14.9221ZM59.4585 14.4052L59.1945 13.5363L58.9306 14.4052H59.4585Z"
                fill="#616161"
            />
            <path
                d="M60.7559 12.9141H61.4487L62.3616 14.2558V12.9141H63.0655V15.3337H62.3616L61.4597 14.0029V15.3337H60.7559V12.9141Z"
                fill="#616161"
            />
            <path
                d="M63.3955 12.9141H65.6721V13.508H64.9133V15.3337H64.1654V13.508H63.4065V12.9141H63.3955Z"
                fill="#616161"
            />
            <path
                d="M36.0981 16.5962L38.2648 15.9143L37.5609 14.3526C37.044 15.0015 36.5271 15.8374 36.0981 16.5962ZM35.3613 9.45842L31.116 0L0.0351562 1.75971L8.64672 25.2518L25.221 20.0277C24.1651 18.5099 23.7802 16.7062 25.001 15.8264C26.3648 14.8365 28.4214 15.9803 29.7302 17.5971C30.995 15.4854 34.5474 10.5692 35.3613 9.45842Z"
                fill="#616161"
            />
            <path
                d="M30.1476 6.83389C31.4453 6.83389 32.5012 5.84405 32.5012 4.61225C32.5012 3.38046 31.4453 2.39062 30.1476 2.39062C28.8498 2.39062 27.7939 3.38046 27.7939 4.61225C27.7939 5.83305 28.8498 6.83389 30.1476 6.83389ZM28.2229 7.79073H32.0722V17.854H28.2229V7.79073Z"
                fill="white"
            />
            <path
                d="M26.3195 10.7293C26.3525 10.7403 26.3745 10.7293 26.3745 10.6963V8.14471C26.3745 8.11172 26.3525 8.07872 26.3195 8.05673C26.3195 8.05673 25.8136 7.72678 24.2738 7.6388C24.1968 7.5948 22.8441 7.5838 22.4811 7.6388C16.8721 8.08972 16.6631 12.148 16.6631 12.324V13.3138C16.6631 13.4348 16.6631 17.5811 22.4811 17.9441C23.053 17.9881 24.1638 17.9441 24.2738 17.9441C25.6156 17.9441 26.5394 17.5371 26.5394 17.5371C26.5724 17.5261 26.5944 17.4932 26.5944 17.4602V15.0736C26.5944 15.0406 26.5724 15.0296 26.5504 15.0516C26.5504 15.0516 26.1325 15.3815 24.3068 15.5685C23.7899 15.6235 23.5369 15.6015 23.35 15.5685C20.7544 15.1285 20.6334 13.2369 20.6334 13.2369C20.6334 13.2039 20.6224 13.1489 20.6224 13.1269V12.401C20.6224 12.368 20.6224 12.313 20.6334 12.291C20.6334 12.291 20.8094 10.2564 23.35 10.0364H24.3068C25.4176 10.1794 26.3195 10.7293 26.3195 10.7293Z"
                fill="white"
            />
            <path
                d="M5.97363 17.8245C5.97363 17.8575 5.99563 17.8795 6.02862 17.8795H9.779C9.81199 17.8795 9.83399 17.8575 9.83399 17.8245V14.899C9.83399 14.866 9.85599 14.844 9.88898 14.844C9.88898 14.844 15.883 15.2729 15.883 11.2586C15.883 8.08011 12.1216 7.73917 10.8898 7.79416C10.8678 7.79416 6.02862 7.79416 6.02862 7.79416C5.99563 7.79416 5.97363 7.81616 5.97363 7.84915V17.8245ZM9.768 12.6114V9.98279H10.6918C10.6918 9.98279 12.0226 10.0378 12.1326 10.9506C12.1546 11.0166 12.1546 11.4675 12.1326 11.4895C11.9566 12.5564 10.8018 12.6114 10.8018 12.6114H9.768Z"
                fill="white"
            />
            <path
                d="M30.2582 22.7098C30.5662 22.7098 30.8081 22.7098 31.2041 22.5339C32.5678 21.819 37.1761 10.5679 42.0373 7.10344C42.0703 7.08144 42.1033 7.04845 42.1252 7.01546C42.1582 6.97146 42.1582 6.92747 42.1582 6.92747C42.1582 6.92747 42.1582 6.69651 41.4434 6.69651C37.1211 6.57553 32.6228 15.649 30.2582 19.2344C30.2252 19.2784 30.0713 19.2344 30.0713 19.2344C30.0713 19.2344 28.4875 17.3647 27.1127 16.6499C27.0798 16.6389 26.9258 16.5839 26.7608 16.5949C26.6508 16.5949 26.0129 16.7268 25.716 17.0348C25.364 17.4087 25.375 17.6177 25.375 18.0686C25.375 18.1016 25.397 18.2556 25.441 18.3326C25.782 18.9265 27.3107 21.0381 28.5755 22.2039C28.7625 22.3469 29.0594 22.7098 30.2582 22.7098Z"
                fill="#0D2542"
            />
            <path
                d="M43.3135 3.70312H46.9099C47.6248 3.70312 48.1967 3.80211 48.6256 3.98908C49.0655 4.18705 49.4285 4.462 49.7034 4.81394C49.9894 5.17688 50.1983 5.59481 50.3193 6.06773C50.4513 6.54065 50.5173 7.04657 50.5173 7.58548C50.5173 8.42134 50.4183 9.07023 50.2313 9.53215C50.0444 9.99408 49.7804 10.379 49.4395 10.698C49.0985 11.0059 48.7356 11.2149 48.3506 11.3249C47.8227 11.4678 47.3388 11.5338 46.9099 11.5338H43.3135V3.70312ZM45.7331 5.47383V9.75212H46.327C46.8329 9.75212 47.1958 9.69713 47.4048 9.58714C47.6138 9.47716 47.7897 9.2792 47.9107 9.00424C48.0317 8.72929 48.0977 8.26736 48.0977 7.64047C48.0977 6.80461 47.9657 6.2437 47.6907 5.93575C47.4158 5.6278 46.9759 5.47383 46.338 5.47383H45.7331Z"
                fill="#616161"
            />
            <path
                d="M51.1885 8.9516L53.4981 8.80862C53.5531 9.18256 53.6521 9.46852 53.806 9.66648C54.059 9.98543 54.4109 10.1394 54.8729 10.1394C55.2138 10.1394 55.4888 10.0624 55.6757 9.89744C55.8627 9.73247 55.9617 9.5455 55.9617 9.33654C55.9617 9.13857 55.8737 8.9516 55.6977 8.78663C55.5218 8.62166 55.1038 8.47868 54.4549 8.3357C53.3881 8.09375 52.6402 7.7748 52.1783 7.37886C51.7164 6.98293 51.4964 6.47701 51.4964 5.86112C51.4964 5.45419 51.6174 5.08025 51.8484 4.71731C52.0793 4.35437 52.4313 4.07941 52.9042 3.87045C53.3771 3.66148 54.015 3.5625 54.8289 3.5625C55.8297 3.5625 56.5996 3.74947 57.1165 4.12341C57.6444 4.49734 57.9523 5.09125 58.0513 5.90511L55.7637 6.03709C55.6977 5.68515 55.5767 5.43219 55.3788 5.26722C55.1808 5.10224 54.9169 5.02526 54.5759 5.02526C54.29 5.02526 54.081 5.08025 53.938 5.20123C53.795 5.32221 53.7291 5.46518 53.7291 5.64115C53.7291 5.76213 53.784 5.88311 53.905 5.9821C54.015 6.08108 54.29 6.18006 54.7189 6.26805C55.7747 6.49901 56.5336 6.72997 56.9955 6.96093C57.4574 7.1919 57.7874 7.48885 57.9963 7.82979C58.2053 8.17073 58.3043 8.56667 58.3043 8.99559C58.3043 9.50151 58.1613 9.96343 57.8864 10.3814C57.6114 10.8103 57.2155 11.1292 56.7206 11.3492C56.2256 11.5692 55.5877 11.6791 54.8289 11.6791C53.4981 11.6791 52.5742 11.4262 52.0573 10.9093C51.5514 10.3924 51.2655 9.74347 51.1885 8.9516Z"
                fill="#616161"
            />
            <path
                d="M58.9199 8.9516L61.2295 8.80862C61.2845 9.18256 61.3835 9.46852 61.5375 9.66648C61.7904 9.98543 62.1424 10.1394 62.6043 10.1394C62.9453 10.1394 63.2202 10.0624 63.4072 9.89744C63.5941 9.73247 63.6931 9.5455 63.6931 9.33654C63.6931 9.13857 63.6051 8.9516 63.4292 8.78663C63.2532 8.62166 62.8353 8.47868 62.1864 8.3357C61.1196 8.09375 60.3717 7.7748 59.9098 7.37886C59.4588 6.98293 59.2279 6.47701 59.2279 5.86112C59.2279 5.45419 59.3489 5.08025 59.5798 4.71731C59.8108 4.35437 60.1627 4.07941 60.6356 3.87045C61.1086 3.66148 61.7464 3.5625 62.5603 3.5625C63.5611 3.5625 64.331 3.74947 64.8479 4.12341C65.3758 4.49734 65.6838 5.09125 65.7828 5.90511L63.4952 6.03709C63.4292 5.68515 63.3082 5.43219 63.1102 5.26722C62.9123 5.10224 62.6483 5.02526 62.3074 5.02526C62.0214 5.02526 61.8124 5.08025 61.6695 5.20123C61.5265 5.32221 61.4605 5.46518 61.4605 5.64115C61.4605 5.76213 61.5155 5.88311 61.6365 5.9821C61.7465 6.08108 62.0214 6.18006 62.4503 6.26805C63.5062 6.49901 64.265 6.72997 64.727 6.96093C65.1889 7.1919 65.5188 7.48885 65.7278 7.82979C65.9367 8.17073 66.0357 8.56667 66.0357 8.99559C66.0357 9.50151 65.8928 9.96343 65.6178 10.3814C65.3428 10.8103 64.9469 11.1292 64.452 11.3492C63.9571 11.5692 63.3192 11.6791 62.5603 11.6791C61.2295 11.6791 60.3057 11.4262 59.7888 10.9093C59.2719 10.3924 58.9859 9.74347 58.9199 8.9516Z"
                fill="#616161"
            />
        </svg>
    )
}

export default IcPCJdssGrey
