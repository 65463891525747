import { twMerge } from 'tailwind-merge';

const IcInfo = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      className={twMerge('text-secondary', props.className)}
    >
      <g clipPath="url(#clip0_4001_116)">
        <g clipPath="url(#clip1_4001_116)">
          <path
            d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
            fill="currentColor"
          />
          <path
            d="M11.9997 14C11.6376 14 11.2895 13.8598 11.0284 13.6089C10.7673 13.358 10.6134 13.0158 10.5989 12.6539L10.4142 7.98828C10.4068 7.79969 10.4375 7.61154 10.5045 7.43512C10.5716 7.2587 10.6736 7.09763 10.8044 6.96157C10.9352 6.82552 11.0921 6.71727 11.2658 6.64333C11.4394 6.56938 11.6262 6.53126 11.8149 6.53125H12.1845C12.3732 6.53126 12.56 6.56938 12.7337 6.64333C12.9073 6.71727 13.0642 6.82552 13.195 6.96157C13.3258 7.09763 13.4278 7.2587 13.4949 7.43512C13.5619 7.61154 13.5927 7.79969 13.5853 7.98828L13.4005 12.6539C13.3861 13.0158 13.2322 13.358 12.9711 13.6089C12.7099 13.8598 12.3619 14 11.9997 14Z"
            fill="white"
          />
          <path
            d="M13.3998 16.0695C13.3998 15.2965 12.7732 14.6699 12.0002 14.6699C11.2272 14.6699 10.6006 15.2965 10.6006 16.0695C10.6006 16.8425 11.2272 17.4691 12.0002 17.4691C12.7732 17.4691 13.3998 16.8425 13.3998 16.0695Z"
            fill="white"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_4001_116">
          <rect width="24" height="24" fill="white" />
        </clipPath>
        <clipPath id="clip1_4001_116">
          <rect
            width="20"
            height="20"
            fill="white"
            transform="translate(2 2)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default IcInfo;
