import clsx from "clsx"

type Props = {
    variant?: "5xl" | "4xl" | "3xl" | "2xl" | "l" | "m" | "s" | "xs" | "xxs"
    children: string
    onClick?: () => void
    className?: string
    as?: "p" | "li" | "a"
}

const TwText = ({ as = "p", variant = "m", children, onClick, className }: Props) => {
    const Comp = as

    const variantClasses = {
        "5xl": "text-5xl-mobile md:text-5xl",
        "4xl": "text-4xl-mobile md:text-4xl",
        "3xl": "text-3xl-mobile md:text-3xl",
        "2xl": "text-2xl-mobile md:text-2xl",
        l: "text-l-mobile md:text-l",
        m: "text-m-mobile md:text-m",
        s: "text-s-mobile md:text-s",
        xs: "text-xs-mobile md:text-xs",
        xxs: "text-xxs-mobile md:text-xxs",
    }

    return (
        <Comp
            className={clsx("text-blue-900", variantClasses[variant], className)}
            onClick={onClick}
            dangerouslySetInnerHTML={{ __html: children }}
        />
    )
}

export default TwText
