import { howItsWorkData } from "@/constants/local/resto/howItsWork.ts"
import { ourMissionData } from "@/constants/local/resto/ourMission.ts"
import { ourPartnerData } from "@/constants/local/resto/ourPartner.ts"
import { faqData } from "@/constants/local/resto/faqData.ts"
import { homeData } from "@/constants/local/resto/homeData.ts"

export const staticRestoData = {
    pages: {
        howItWorks: howItsWorkData,
        ourMission: ourMissionData,
        ourPartner: ourPartnerData,
        faq: faqData,
        home: homeData,
    },
}
