const IcHotelColor = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 39 38"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_1627_35152)">
      <path
        d="M22.5002 15.8333C22.5002 16.9531 22.0553 18.0271 21.2635 18.8189C20.4717 19.6107 19.3977 20.0556 18.2779 20.0556H4.55572C3.43592 20.0556 2.36198 19.6107 1.57016 18.8189C0.778336 18.0271 0.333496 16.9531 0.333496 15.8333V12.6667C0.333496 11.5469 0.778336 10.4729 1.57016 9.68112C2.36198 8.8893 3.43592 8.44446 4.55572 8.44446H18.2779C19.3977 8.44446 20.4717 8.8893 21.2635 9.68112C22.0553 10.4729 22.5002 11.5469 22.5002 12.6667V15.8333Z"
        fill="#C1694F"
      />
      <path
        d="M38.3335 6.33332C38.3335 7.45312 37.8887 8.52706 37.0969 9.31888C36.305 10.1107 35.2311 10.5555 34.1113 10.5555H21.4446C20.3248 10.5555 19.2509 10.1107 18.4591 9.31888C17.6673 8.52706 17.2224 7.45312 17.2224 6.33332V5.27776C17.2224 4.15796 17.6673 3.08402 18.4591 2.2922C19.2509 1.50038 20.3248 1.05554 21.4446 1.05554H34.1113C35.2311 1.05554 36.305 1.50038 37.0969 2.2922C37.8887 3.08402 38.3335 4.15796 38.3335 5.27776V6.33332Z"
        fill="#C1694F"
      />
      <path
        d="M0.333496 12.6666V35.8888C0.333496 36.4487 0.555916 36.9857 0.951826 37.3816C1.34774 37.7775 1.88471 38 2.44461 38H35.1668V12.6666H0.333496Z"
        fill="#FFCC4D"
      />
      <path
        d="M2.44458 33.7778H15.1112V38H2.44458V33.7778ZM2.44458 27.4445H19.3335V31.6667H2.44458V27.4445Z"
        fill="#55ACEE"
      />
      <path
        d="M17.2224 5.27783V38.0001H36.2224C36.7823 38.0001 37.3193 37.7776 37.7152 37.3817C38.1111 36.9858 38.3335 36.4488 38.3335 35.8889V5.27783H17.2224Z"
        fill="#FFE8B6"
      />
      <path
        d="M19.3335 21.1111H36.2224V25.3333H19.3335V21.1111ZM19.3335 14.7778H36.2224V19H19.3335V14.7778ZM19.3335 8.44446H36.2224V12.6667H19.3335V8.44446ZM19.3335 27.4445H36.2224V31.6667H19.3335V27.4445Z"
        fill="#55ACEE"
      />
      <path
        d="M23.5557 7.38892H25.6668V32.7222H23.5557V7.38892ZM29.889 7.38892H32.0001V32.7222H29.889V7.38892Z"
        fill="#FFE8B6"
      />
      <path
        d="M23.5557 33.7778H32.0001V38.0001H23.5557V33.7778ZM10.889 14.7778V19.0001H6.66678V14.7778H4.55566V25.3334H6.66678V21.1112H10.889V25.3334H13.0001V14.7778H10.889Z"
        fill="#3B88C3"
      />
      <path
        d="M7.72241 26.3889H9.83352V32.7222H7.72241V26.3889ZM15.1113 26.3889H17.2224V32.7222H15.1113V26.3889Z"
        fill="#FFCC4D"
      />
    </g>
    <defs>
      <clipPath id="clip0_1627_35152">
        <rect
          width="38"
          height="38"
          fill="white"
          transform="translate(0.333496)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default IcHotelColor;
