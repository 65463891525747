const IcClose = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 13 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_2521_1388)">
      <path
        d="M11.7825 0.436523L0.436523 11.7825"
        stroke="#0D2542"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M0.436523 0.436523L11.7825 11.7825"
        stroke="#0D2542"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_2521_1388">
        <rect width="12.2188" height="12.2188" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default IcClose;
