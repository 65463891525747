const IcMastercardGrey = (props: React.SVGProps<SVGSVGElement>) => {
    return (
        <svg width="41" height="26" viewBox="0 0 41 26" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M28.4816 11.3516C28.1698 11.3645 27.8191 11.3516 27.1565 11.4816L27.0006 12.2867C27.2477 12.261 27.9102 12.1571 28.2608 12.1698C28.6117 12.1828 29.0014 12.131 28.8843 12.6895C28.6246 12.7156 26.8059 12.4296 26.5202 13.9883C26.4032 14.755 27.0657 15.1445 27.5853 15.1185C28.1048 15.0923 28.2475 15.0277 28.4296 14.8977L28.5597 15.1057H29.5078L29.9107 13.0794C30.0146 12.5729 30.1833 11.3516 28.4816 11.3516ZM28.6376 13.8453C28.5721 14.1837 28.0924 14.3992 27.7674 14.2741C27.4295 14.144 27.5203 13.7546 27.8451 13.5337C28.1699 13.313 28.5466 13.4165 28.7156 13.443L28.6376 13.8453Z"
                fill="#616161"
            />
            <path
                d="M25.6495 11.4699C25.9872 11.4699 26.1691 11.4958 26.4288 11.6517L27.0914 10.9892C26.8317 10.7033 26.5328 10.4225 25.7013 10.3915C25.0001 10.3658 23.7981 10.8776 23.4152 12.2752C22.8954 14.1716 24.0386 15.1071 25.0909 15.1071C25.6495 15.1071 25.9872 15.0292 26.3251 14.873L26.0263 13.9641C24.9872 14.3146 24.2466 13.7818 24.4155 12.7039C24.5584 11.7927 25.3119 11.4699 25.6495 11.4699Z"
                fill="#616161"
            />
            <path
                d="M15.179 13.1475C14.9842 13.0179 14.6594 12.836 14.4516 12.7448C14.2438 12.6541 14.0878 12.3033 14.4647 12.1995C14.6983 12.1733 15.179 12.1733 15.4649 12.2514L15.6985 11.4978C15.2179 11.316 14.8412 11.3551 14.6983 11.3551C14.5554 11.3551 13.339 11.3401 13.1525 12.2383C13.0097 12.9266 13.2618 13.2117 13.5032 13.3165C13.8931 13.4855 14.114 13.6413 14.3218 13.7452C14.5789 13.8734 14.5317 14.3037 14.1918 14.3037C14.0357 14.3037 13.3603 14.3816 12.8538 14.2126L12.7109 14.979C13.1786 15.1091 13.555 15.1091 13.9061 15.1091C14.1529 15.1091 15.3609 15.1741 15.5296 14.0568C15.6122 13.512 15.3738 13.2644 15.179 13.1475Z"
                fill="#616161"
            />
            <path
                d="M20.8171 13.4457C20.9339 12.8479 21.2069 11.3672 19.4272 11.3672C17.7904 11.3672 17.5826 12.9908 17.5437 13.2245C17.5047 13.4586 17.4787 15.1082 19.2321 15.1082C19.6611 15.121 20.2844 15.0693 20.5315 14.9392L20.6873 14.1727C20.5315 14.2117 19.947 14.2639 19.5442 14.2639C19.1415 14.2639 18.4921 14.29 18.6088 13.4457H20.8171ZM19.4793 12.0946C19.8818 12.0946 19.9988 12.4064 19.9338 12.77H18.6736C18.7906 12.3024 19.0763 12.0946 19.4793 12.0946Z"
                fill="#616161"
            />
            <path
                d="M22.4972 11.7816L22.4063 11.418H21.5357L20.8477 15.107H21.8607L22.1725 13.418C22.471 12.3144 22.9517 12.3921 23.1985 12.3921L23.4584 11.418C22.9515 11.418 22.6529 11.5996 22.4972 11.7816Z"
                fill="#616161"
            />
            <path
                d="M38.1047 0H2.38232C1.06661 0 0 1.06661 0 2.38232V22.7768C0 24.0925 1.06661 25.1591 2.38232 25.1591H38.1047C39.4204 25.1591 40.487 24.0925 40.487 22.7768V2.38232C40.487 1.06661 39.4204 0 38.1047 0ZM27.2321 22.8162C24.8186 22.8162 22.6228 21.9715 20.8884 20.556C22.2113 19.2787 23.2212 17.6794 23.7631 15.8717H22.9262C22.402 17.4787 21.481 18.8969 20.2835 20.0346C19.0933 18.8999 18.1807 17.4764 17.658 15.8762H16.8211C17.3607 17.6737 18.3534 19.2753 19.6661 20.5487C17.936 21.95 15.7503 22.8162 13.3506 22.8162C7.78893 22.8162 3.2801 18.3074 3.2801 12.746C3.2801 7.18422 7.78893 2.67547 13.3506 2.67547C15.7503 2.67547 17.936 3.54162 19.6661 4.94289C18.3534 6.21614 17.3607 7.81772 16.8211 9.61517H17.658C18.1807 8.01533 19.0933 6.59176 20.2835 5.45682C21.481 6.59484 22.402 8.0128 22.9262 9.62L23.7631 9.61992C23.2212 7.81234 22.2113 6.21289 20.8884 4.93577C22.6229 3.52003 24.8187 2.67547 27.2321 2.67547C32.7937 2.67547 37.3025 7.1843 37.3025 12.746C37.3025 18.3074 32.7937 22.8162 27.2321 22.8162Z"
                fill="#616161"
            />
            <path
                d="M31.5676 11.7463L31.476 11.3789H30.5969L29.9014 15.1051H30.9249L31.2399 13.3991C31.5416 12.2844 32.0139 12.3498 32.2632 12.3498L32.5386 11.379C32.0271 11.3789 31.7252 11.5624 31.5676 11.7463Z"
                fill="#616161"
            />
            <path
                d="M8.07429 10.6133L6.84003 13.3414L6.64518 10.6133H5.02146L4.15112 15.1079H5.16428L5.78773 11.8603L6.06056 15.1079H7.02199L8.43798 11.9384L7.84022 15.1079H8.8926L9.73692 10.6133H8.07429Z"
                fill="#616161"
            />
            <path
                d="M16.7907 13.7289L17.0767 12.2222H17.5963L17.778 11.4169H17.2326L17.4404 10.4297H16.4271L15.7258 13.9629C15.5307 14.9758 16.2974 15.1187 16.5311 15.1187C16.7649 15.1187 17.0637 15.0797 17.2845 14.9369L17.3496 14.1838C16.7649 14.2746 16.7388 14.0148 16.7907 13.7289Z"
                fill="#616161"
            />
            <path
                d="M32.3525 12.9509C32.0227 14.0795 32.6901 15.1072 33.4175 15.1072C33.7553 15.0939 34.093 14.9513 34.3008 14.8085L34.4309 15.1072H35.522L36.4053 10.3789H35.34L35.0674 11.8077C34.8722 11.665 34.7556 11.3662 34.2749 11.3662C33.7164 11.3661 32.8459 11.2623 32.3525 12.9509ZM34.6386 12.4185C34.7509 12.5682 34.8077 12.9898 34.7037 13.3274C34.5217 13.7819 34.2882 14.081 33.9112 14.081C33.5607 14.081 33.249 13.678 33.3917 13.1456C33.6102 12.3314 34.3271 12.0028 34.6386 12.4185Z"
                fill="#616161"
            />
            <path
                d="M11.3346 11.3516C11.0229 11.3645 10.6722 11.3516 10.0096 11.4816L9.85362 12.2867C10.1007 12.261 10.7632 12.1571 11.1137 12.1698C11.4647 12.1828 11.8542 12.131 11.7372 12.6895C11.4773 12.7156 9.65893 12.4296 9.37322 13.9883C9.25618 14.755 9.91862 15.1445 10.4382 15.1185C10.9579 15.0923 11.1007 15.0277 11.2827 14.8977L11.4127 15.1057H12.3608L12.7636 13.0794C12.8675 12.5729 13.0362 11.3516 11.3346 11.3516ZM11.4905 13.8453C11.4251 14.1837 10.9453 14.3992 10.6201 14.2741C10.2823 14.144 10.3731 13.7546 10.698 13.5337C11.0229 13.313 11.3996 13.4165 11.5685 13.443L11.4905 13.8453Z"
                fill="#616161"
            />
        </svg>
    )
}

export default IcMastercardGrey
