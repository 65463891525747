export const ourPartnerData = {
    title: "Notre partenaire",
    description: "Au cœur de votre quotidien : découvrez nos conciergeries partenaires.",
    achievement: [
        {
            value: "95%",
            description: "utilisateurs satisfaits",
        },
        {
            value: "+10 000",
            description: "réservations mensuelles",
        },
        {
            value: "97%",
            description: "demandes traitées en moins de 10 mins",
        },
        {
            value: "85%",
            description: "de clients récurrents",
        },
    ],
    presentation: {
        title: "Présentation de nos partenaires",
        description:
            "Nos partenaires de conciergerie, soigneusement sélectionnés pour leur expertise, sont répartis partout en France. Ils sont engagés à offrir un service exceptionnel, avec la possibilité d'annuler à tout moment.<span class='mt-6 block'>Chaque conciergerie partenaire est choisie pour son excellence et sa capacité à garantir la satisfaction totale de chaque client, soutenue par une promesse de remboursement si vous n'êtes pas satisfait.</span>",
        inforBar:
            "Une fois votre demande soumise, elle ne garantit pas une réservation immédiate. La confirmation dépend de l'acceptation par le restaurant. Si la demande ne peut être honorée, nos concierges partenaires vous proposeront des alternatives valides.",
        image: "/images/counselors-2.png",
    },
    assetSimplifylife: {
        title: "L’atout pour vous simplifier la vie",
        description:
            "Nos concierges partenaires sont là pour simplifier vos réservations, en gérant toutes vos demandes avec expertise.",
        advantages: [
            "<strong>Gain de temps :</strong> Vos requêtes sont traitées en quelques minutes, assurant une gestion sans délai.",
            "<strong>Gestion efficace :</strong> Une équipe d'assistance toujours disponible pour répondre à vos besoins.",
            "<strong>Rapidité & fléxibilité :</strong> Besoins de dernière minute? Nos partenaires réagissent instantanément.",
        ],
        image: "/images/relax.png",
    },
    howItWork: {
        title: "Les engagements pour votre sérénité",
        class: { title: "!text-blue-500", itemBox: "md:grid-cols-4" },
        items: [
            {
                icon: "ic-shield",
                title: "Garantie satisfait ou remboursé",
                description: "Une garantie totale sur tous les services en cas d'insatisfaction.",
            },
            {
                icon: "ic-customer-service",
                title: "Assistance de qualité",
                description: "Des agents qualifiés prêts à vous assister à chaque étape.",
            },
            {
                icon: "ic-high-quality",
                title: "Respect des normes élevées",
                description: "Chaque service est rendu selon des critères stricts de qualité et d'efficacité.",
            },
            {
                icon: "ic-lock",
                title: "Confidentialité et sécurité",
                description: "Vos informations restent protégées et sécurisées à tout moment.",
            },
        ],
    },
}
