const IcPlane = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M3.60596 6.37606L7.67196 2.31006L8.66196 3.30006L4.59596 7.36606L3.60596 6.37606ZM10.7105 7.39856L14.777 3.33356L15.767 4.32356L11.7005 8.38856L10.7105 7.39856ZM24.655 27.4271L28.722 23.3621L29.712 24.3526L25.645 28.4176L24.655 27.4271ZM23.6395 20.2786L27.7065 16.2136L28.6965 17.2041L24.629 21.2691L23.6395 20.2786Z"
      fill="#ACB8BF"
    />
    <path
      d="M28.2 30.35L25.85 9.30004L22.7 6.15004L1.64999 3.80004C0.649991 3.70004 0.849991 6.20004 1.99999 6.75004L17.85 14.15L25.25 30C25.8 31.15 28.3 31.35 28.2 30.35Z"
      fill="#42ADE2"
    />
    <path
      d="M30.65 4.04993C31.75 1.89993 30.1 0.249926 27.95 1.34993C25.2 2.74993 21.15 5.89993 17.05 9.94993C10.65 16.3499 6.54996 22.6999 7.89996 24.0999C9.24996 25.4499 15.65 21.3499 22.05 14.9499C26.1 10.8999 29.25 6.79993 30.65 4.04993Z"
      fill="#DAE3EA"
    />
    <path
      d="M11.2001 30.1001L10.4001 22.7001L9.30007 21.6001L1.90007 20.8001C1.55007 20.7501 1.60007 21.6501 2.00007 21.8501L7.55007 24.4501L10.1501 30.0001C10.3501 30.4001 11.2501 30.4501 11.2001 30.1001Z"
      fill="#42ADE2"
    />
    <path
      d="M10.1 23.1C7.85004 25.35 5.80004 26.9 5.50004 26.55C5.20004 26.25 6.75004 24.15 8.95004 21.9C11.2 19.65 13.25 18.1 13.6 18.45C13.85 18.75 12.3 20.85 10.1 23.1Z"
      fill="#ACB8BF"
    />
    <path
      d="M29.9 4.8499C30.15 3.9499 30.05 3.0999 29.5 2.4999C28.95 1.9499 28.05 1.7999 27.2 2.0999L25.5 3.1499C26.35 2.8499 27.6 3.2999 28.15 3.8499C28.75 4.4499 29.15 5.6499 28.85 6.4999L29.9 4.8499Z"
      fill="#3E4347"
    />
    <path
      d="M26.832 4.84757L29.659 2.01807L29.9775 2.33607L27.15 5.16607L26.832 4.84757Z"
      fill="#DAE3EA"
    />
  </svg>
);

export default IcPlane;
