import { Box } from "@chakra-ui/react"

const BoxColumn = ({ children, ...rest }) => {
    return (
        <Box maxW={{ base: "500px", lg: "1500px" }} px={{ base: "20px", lg: "80px" }} mx="auto" {...rest}>
            {children}
        </Box>
    )
}

export default BoxColumn
