import { twMerge } from 'tailwind-merge';

const IcSearchDefault = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      {...props}
      className={twMerge(props.className)}
    >
      <path
        d="M8.50476 16.7146C12.6495 16.7146 16.0095 13.3546 16.0095 9.20984C16.0095 5.06508 12.6495 1.70508 8.50476 1.70508C4.36 1.70508 1 5.06508 1 9.20984C1 13.3546 4.36 16.7146 8.50476 16.7146Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.2022 19.7061L14.0098 14.5137"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default IcSearchDefault;
