import React, { Suspense } from "react"
import FallbackLoading from "../FallbackLoading"

const FAQ = React.lazy(() => import("./FAQ"))
const TwFaq = React.lazy(() => import("./TwFaq.tsx"))

const FAQPage = () => {
    return (
        <Suspense fallback={<FallbackLoading />}>
            {import.meta.env.VITE_VERTICALE !== "taxi" ? <TwFaq /> : <FAQ />}
        </Suspense>
    )
}

export default FAQPage
