const IcEyeSlash = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="20"
    height="16"
    viewBox="0 0 20 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M3 1L17 15M10 1C4.098 1 1.91159 5.33484 1.24782 7.18207C1.05675 7.7138 1.05675 8.2862 1.24782 8.81793C1.91159 10.6652 4.098 15 10 15C15.902 15 18.0884 10.6652 18.7522 8.81793C18.9433 8.2862 18.9433 7.7138 18.7522 7.18207C18.0884 5.33485 15.902 1 10 1ZM10 11C11.6569 11 13 9.65685 13 8C13 6.34315 11.6569 5 10 5C8.34315 5 7 6.34315 7 8C7 9.65685 8.34315 11 10 11Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default IcEyeSlash;
