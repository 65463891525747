const IcPhone = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 49 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12.3335 40V8C12.3335 5.8 14.1335 4 16.3335 4H32.3335C34.5335 4 36.3335 5.8 36.3335 8V40C36.3335 42.2 34.5335 44 32.3335 44H16.3335C14.1335 44 12.3335 42.2 12.3335 40Z"
      fill="#37474F"
    />
    <path
      d="M32.3335 7H16.3335C15.7335 7 15.3335 7.4 15.3335 8V37C15.3335 37.6 15.7335 38 16.3335 38H32.3335C32.9335 38 33.3335 37.6 33.3335 37V8C33.3335 7.4 32.9335 7 32.3335 7Z"
      fill="#BBDEFB"
    />
    <path d="M21.3335 40H27.3335V42H21.3335V40Z" fill="#78909C" />
  </svg>
);
export default IcPhone;
