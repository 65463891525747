import { defineStyle, defineStyleConfig } from "@chakra-ui/react"

const lg = defineStyle({
    color: "#000000",
    fontWeight: 700,
    fontSize: {
        base: "22px",
        lg: "40px",
    },
})

const variants = {
    lg,
}

export const headingTheme = defineStyleConfig({
    variants,
})
