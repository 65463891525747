const IcEmail = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 20 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M1 3V13C1 14.1046 1.89543 15 3 15H17C18.1046 15 19 14.1046 19 13V3M1 3V3C1 1.89543 1.89543 1 3 1H17C18.1046 1 19 1.89543 19 3V3M1 3L8.8906 8.2604C9.5624 8.70827 10.4376 8.70827 11.1094 8.2604L19 3"
      stroke="#09090B"
      strokeLinejoin="round"
    />
  </svg>
);

export default IcEmail;
