const IcHourglassBasic = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="16"
    height="21"
    viewBox="0 0 16 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <mask
      id="path-1-outside-1_1800_52374"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="16"
      height="21"
      fill="black"
    >
      <rect fill="white" width="16" height="21" />
      <path d="M3.964 18.9444H12.8138V15.7778C12.8138 14.535 12.387 13.4735 11.5334 12.5932C10.6798 11.7114 9.63163 11.2706 8.38889 11.2706C7.14615 11.2706 6.09798 11.7111 5.24439 12.5921C4.3908 13.4731 3.964 14.535 3.964 15.7778V18.9444ZM8.38889 9.72944C9.63163 9.72944 10.6798 9.28857 11.5334 8.40683C12.387 7.52509 12.8138 6.46356 12.8138 5.22222V2.05556H3.964V5.22222C3.964 6.46496 4.3908 7.5265 5.24439 8.40683C6.09798 9.28857 7.14615 9.72944 8.38889 9.72944ZM1 20V18.9444H2.90844V15.7778C2.90844 14.4745 3.3078 13.3187 4.1065 12.3103C4.9052 11.3019 5.92909 10.6984 7.17817 10.5C5.9298 10.2889 4.90591 9.6823 4.1065 8.68022C3.30709 7.67815 2.90774 6.52548 2.90844 5.22222V2.05556H1V1H15.7778V2.05556H13.8693V5.22222C13.8693 6.52548 13.47 7.67815 12.6713 8.68022C11.8726 9.6823 10.8487 10.2889 9.59961 10.5C10.848 10.697 11.8719 11.3005 12.6713 12.3103C13.47 13.3187 13.8693 14.4745 13.8693 15.7778V18.9444H15.7778V20H1Z" />
    </mask>
    <path
      d="M3.964 18.9444H12.8138V15.7778C12.8138 14.535 12.387 13.4735 11.5334 12.5932C10.6798 11.7114 9.63163 11.2706 8.38889 11.2706C7.14615 11.2706 6.09798 11.7111 5.24439 12.5921C4.3908 13.4731 3.964 14.535 3.964 15.7778V18.9444ZM8.38889 9.72944C9.63163 9.72944 10.6798 9.28857 11.5334 8.40683C12.387 7.52509 12.8138 6.46356 12.8138 5.22222V2.05556H3.964V5.22222C3.964 6.46496 4.3908 7.5265 5.24439 8.40683C6.09798 9.28857 7.14615 9.72944 8.38889 9.72944ZM1 20V18.9444H2.90844V15.7778C2.90844 14.4745 3.3078 13.3187 4.1065 12.3103C4.9052 11.3019 5.92909 10.6984 7.17817 10.5C5.9298 10.2889 4.90591 9.6823 4.1065 8.68022C3.30709 7.67815 2.90774 6.52548 2.90844 5.22222V2.05556H1V1H15.7778V2.05556H13.8693V5.22222C13.8693 6.52548 13.47 7.67815 12.6713 8.68022C11.8726 9.6823 10.8487 10.2889 9.59961 10.5C10.848 10.697 11.8719 11.3005 12.6713 12.3103C13.47 13.3187 13.8693 14.4745 13.8693 15.7778V18.9444H15.7778V20H1Z"
      fill="#0D2542"
    />
    <path
      d="M3.964 18.9444H12.8138V15.7778C12.8138 14.535 12.387 13.4735 11.5334 12.5932C10.6798 11.7114 9.63163 11.2706 8.38889 11.2706C7.14615 11.2706 6.09798 11.7111 5.24439 12.5921C4.3908 13.4731 3.964 14.535 3.964 15.7778V18.9444ZM8.38889 9.72944C9.63163 9.72944 10.6798 9.28857 11.5334 8.40683C12.387 7.52509 12.8138 6.46356 12.8138 5.22222V2.05556H3.964V5.22222C3.964 6.46496 4.3908 7.5265 5.24439 8.40683C6.09798 9.28857 7.14615 9.72944 8.38889 9.72944ZM1 20V18.9444H2.90844V15.7778C2.90844 14.4745 3.3078 13.3187 4.1065 12.3103C4.9052 11.3019 5.92909 10.6984 7.17817 10.5C5.9298 10.2889 4.90591 9.6823 4.1065 8.68022C3.30709 7.67815 2.90774 6.52548 2.90844 5.22222V2.05556H1V1H15.7778V2.05556H13.8693V5.22222C13.8693 6.52548 13.47 7.67815 12.6713 8.68022C11.8726 9.6823 10.8487 10.2889 9.59961 10.5C10.848 10.697 11.8719 11.3005 12.6713 12.3103C13.47 13.3187 13.8693 14.4745 13.8693 15.7778V18.9444H15.7778V20H1Z"
      stroke="#0D2542"
      strokeWidth="0.19"
      mask="url(#path-1-outside-1_1800_52374)"
    />
  </svg>
);

export default IcHourglassBasic;
