const IcAlternativesBg = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M24.2609 25.4995C23.6862 23.9651 19.759 21.2222 15 21.2222C10.241 21.2222 6.31383 23.9651 5.73906 25.4995M24.2609 25.4995C27.1671 22.9342 29 19.1812 29 15C29 7.26801 22.732 1 15 1C7.26801 1 1 7.26801 1 15C1 19.1812 2.83291 22.9342 5.73906 25.4995M24.2609 25.4995C21.7929 27.6781 18.5508 29 15 29C11.4492 29 8.20709 27.6781 5.73906 25.4995M19.6667 11.8889C19.6667 14.4662 17.5773 16.5556 15 16.5556C12.4227 16.5556 10.3333 14.4662 10.3333 11.8889C10.3333 9.31156 12.4227 7.22222 15 7.22222C17.5773 7.22222 19.6667 9.31156 19.6667 11.8889Z"
      stroke="currentColor"
      strokeWidth="2"
    />
  </svg>
);

export default IcAlternativesBg;
