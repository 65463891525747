import { twMerge } from 'tailwind-merge';

const IcPhoneIncoming = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      {...props}
      className={twMerge('text-secondary', props.className)}
    >
      <g clipPath="url(#clip0_4016_1092)">
        <path
          d="M18.252 2.75V9.5H25.002"
          stroke="currentColor"
          strokeWidth="2.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M26.127 1.62402L18.252 9.49902"
          stroke="currentColor"
          strokeWidth="2.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M25.0011 19.5351V22.9101C25.0024 23.2234 24.9382 23.5335 24.8127 23.8206C24.6871 24.1077 24.503 24.3654 24.2722 24.5772C24.0413 24.789 23.7687 24.9503 23.4719 25.0507C23.1751 25.151 22.8606 25.1883 22.5486 25.1601C19.0868 24.784 15.7615 23.601 12.8398 21.7064C10.1216 19.9791 7.81709 17.6746 6.08983 14.9564C4.18856 12.0215 3.00536 8.67998 2.63608 5.20261C2.60797 4.89151 2.64494 4.57796 2.74465 4.28194C2.84435 3.98591 3.0046 3.71388 3.2152 3.48318C3.42579 3.25248 3.68211 3.06816 3.96785 2.94195C4.25358 2.81574 4.56247 2.7504 4.87483 2.75011H8.24984C8.7958 2.74474 9.3251 2.93807 9.73907 3.29408C10.153 3.65009 10.4234 4.14449 10.4998 4.68511C10.6423 5.76518 10.9065 6.82568 11.2873 7.84636C11.4387 8.24902 11.4715 8.68664 11.3817 9.10735C11.292 9.52806 11.0836 9.91423 10.7811 10.2201L9.35234 11.6489C10.9538 14.4653 13.2858 16.7974 16.1023 18.3989L17.5311 16.9701C17.837 16.6676 18.2231 16.4592 18.6438 16.3695C19.0646 16.2797 19.5022 16.3125 19.9048 16.4639C20.9255 16.8447 21.986 17.1089 23.0661 17.2514C23.6126 17.3285 24.1117 17.6037 24.4684 18.0248C24.8252 18.4459 25.0148 18.9834 25.0011 19.5351Z"
          stroke="currentColor"
          strokeWidth="2.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_4016_1092">
          <rect
            width="27"
            height="27"
            fill="white"
            transform="translate(0.250977 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default IcPhoneIncoming;
