const IcMap = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="17"
    height="22"
    viewBox="0 0 17 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8.16012 0C3.74191 0 0 3.76358 0 8.22101C0 12.8893 4.33738 17.6633 7.25479 21.0187C7.26624 21.0321 7.73777 21.5556 8.31842 21.5556H8.36962C8.95094 21.5556 9.4191 21.0321 9.43056 21.0187C12.1681 17.8715 16.1667 12.6811 16.1667 8.22101C16.1667 3.76291 13.2479 0 8.16012 0ZM8.43024 20.1147C8.40667 20.1382 8.37231 20.1645 8.342 20.1867C8.31101 20.1652 8.27733 20.1382 8.25241 20.1147L7.90011 19.7091C5.13426 16.5363 1.34655 12.1907 1.34655 8.22033C1.34655 4.4938 4.46672 1.34592 8.15945 1.34592C12.7595 1.34592 14.8188 4.79828 14.8188 8.22033C14.8188 11.2342 12.6693 15.2369 8.43024 20.1147ZM8.10691 4.07884C5.87524 4.07884 4.06524 5.88822 4.06524 8.12064C4.06524 10.3531 5.87524 12.1624 8.10691 12.1624C10.3386 12.1624 12.1486 10.3524 12.1486 8.12064C12.1486 5.88889 10.3393 4.07884 8.10691 4.07884ZM8.10691 10.8152C6.62092 10.8152 5.38148 9.57703 5.38148 8.091C5.38148 6.60496 6.58994 5.39647 8.07592 5.39647C9.56258 5.39647 10.7704 6.60496 10.7704 8.091C10.771 9.57703 9.59357 10.8152 8.10691 10.8152Z"
      fill="#0D2542"
    />
  </svg>
);

export default IcMap;
