export const ourPartnerData = {
    title: "Votre assistant",
    description:
        "Simplifiez vos réservations avec l'aide de votre assistant personnel. Disponible à tout moment, votre assistant gère vos demandes, que ce soit pour un transport, un service à domicile ou tout autre besoin. Avec une approche sur-mesure, nous veillons à vous offrir une solution rapide et efficace.",
    achievement: [
        {
            value: "98%",
            description: "clients satisfaits",
        },
        {
            value: "+25 000",
            description: "réservations /mois",
        },
        {
            value: "95%",
            description: "demandes traitées en moins de 10’",
        },
        {
            value: "87%",
            description: "de clients fidèles",
        },
    ],
    presentation: {
        title: "Qui est votre assistant ?",
        description:
            "Votre assistant personnel est là pour rendre vos réservations plus simples. En contact direct avec nos prestataires de confiance, il s'assure que chaque demande soit gérée avec soin, en suivant vos préférences et vos besoins spécifiques. Que ce soit pour un transport de dernière minute ou pour organiser une intervention à domicile, votre assistant trouve toujours la solution adaptée.<span class='mt-6 block'>Nous nous assurons que chaque assistant respecte nos standards de qualité afin de garantir votre satisfaction, que ce soit pour une simple réservation ou pour des services plus complexes.</span>",
        inforBar:
            "Une fois votre demande soumise, elle ne garantit pas une réservation immédiate. La confirmation dépend de l'acceptation par l'établissement (coiffeur, spa, etc.). Si la demande ne peut être honorée, nos concierges partenaires vous proposeront des alternatives validées.",
        image: "/images/girl-call.jpeg",
    },
    assetSimplifylife: {
        title: "Pourquoi avoir un assistant ?",
        description:
            "Votre assistant est la clé pour rendre vos réservations plus simples et efficaces. Voici pourquoi nos utilisateurs l’adorent :",
        advantages: [
            "<strong>Gain de temps :</strong> Votre assistant s’occupe de tout, vous n'avez plus qu'à valider.",
            "<strong>Expertise dédiée :</strong> Toujours à l’écoute, votre assistant connaît vos préférences et sait exactement ce dont vous avez besoin.",
            "<strong>Réactivité & fléxibilité :</strong> Même pour des demandes urgentes, votre assistant trouve une solution adaptée en quelques minutes.",
        ],
        image: "/images/massage.jpeg",
    },
    howItWork: {
        title: "Les garanties de votre assistant",
        class: { title: "!text-blue-500", itemBox: "md:grid-cols-4" },
        items: [
            {
                icon: "ic-shield",
                title: "Satisfaction garantie",
                description:
                    "Si le service réservé ne vous convient pas, votre assistant s’engage à trouver une alternative ou à vous rembourser",
            },
            {
                icon: "ic-customer-service",
                title: "Assistance dédiée",
                description: "Votre assistant est disponible 7j/7 pour répondre à vos demandes, sans aucun délai.",
            },
            {
                icon: "ic-high-quality",
                title: "Service sur-mesure",
                description:
                    "Votre assistant personnalise chaque réservation en fonction de vos préférences et besoins spécifiques.",
            },
            {
                icon: "ic-lock",
                title: "Sécurité et confidentialité",
                description:
                    "Toutes vos informations personnelles sont protégées et traitées en toute confidentialité.",
            },
        ],
    },
}
