const IcArrowDown = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 14 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M1 1L6.67692 6.85573C6.71836 6.90127 6.76841 6.93757 6.82397 6.96238C6.87954 6.9872 6.93945 7 7 7C7.06055 7 7.12046 6.9872 7.17603 6.96238C7.23159 6.93757 7.28164 6.90127 7.32308 6.85573L13 1"
      stroke={props.color ?? '#0D2542'}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default IcArrowDown;
