import { twMerge } from 'tailwind-merge';

const IcInfo2 = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      {...props}
      className={twMerge('text-white', props.className)}
    >
      <g clipPath="url(#clip0_4016_55)">
        <path
          d="M19.5 10.7051C19.5 15.9518 15.2467 20.2051 10 20.2051C4.75329 20.2051 0.5 15.9518 0.5 10.7051C0.5 5.45837 4.75329 1.20508 10 1.20508C15.2467 1.20508 19.5 5.45837 19.5 10.7051Z"
          stroke="currentColor"
        />
        <path
          d="M10.0007 12.7051C9.63855 12.7051 9.29047 12.5649 9.02935 12.314C8.76823 12.0631 8.61434 11.7208 8.59991 11.359L8.41514 6.69336C8.40773 6.50477 8.43847 6.31662 8.50552 6.1402C8.57258 5.96378 8.67456 5.80271 8.80537 5.66665C8.93617 5.53059 9.0931 5.42235 9.26675 5.3484C9.4404 5.27446 9.62719 5.23634 9.81593 5.23633H10.1855C10.3742 5.23634 10.561 5.27446 10.7346 5.3484C10.9083 5.42235 11.0652 5.53059 11.196 5.66665C11.3268 5.80271 11.4288 5.96378 11.4959 6.1402C11.5629 6.31662 11.5937 6.50477 11.5862 6.69336L11.4015 11.359C11.387 11.7208 11.2332 12.0631 10.972 12.314C10.7109 12.5649 10.3628 12.7051 10.0007 12.7051Z"
          fill="currentColor"
        />
        <path
          d="M11.4008 14.7741C11.4008 14.0011 10.7742 13.3745 10.0012 13.3745C9.22819 13.3745 8.60156 14.0011 8.60156 14.7741C8.60156 15.5471 9.22819 16.1737 10.0012 16.1737C10.7742 16.1737 11.4008 15.5471 11.4008 14.7741Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_4016_55">
          <rect
            width="20"
            height="20"
            fill="currentColor"
            transform="translate(0 0.705078)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default IcInfo2;
