import TwText from "@/components/tailwind-components/atoms/TwText.tsx"
import { commonData } from "@/constants/config.ts"

type Props = {
    variant?: "5xl" | "4xl" | "3xl" | "2xl" | "l" | "m" | "s" | "xs" | "xxs"
}

const Logo = ({ variant = "3xl" }: Props) => (
    <div>
        <TwText variant={variant} className={"text-white font-bold"}>
            {commonData.brandName}
        </TwText>
    </div>
)

export default Logo
