import React, { Suspense } from "react"
import FallbackLoading from "../FallbackLoading"

const OurPartner = React.lazy(() => import("./OurPartner.tsx"))

const OurPartnerPage = () => {
    return (
        <Suspense fallback={<FallbackLoading />}>
            <OurPartner />
        </Suspense>
    )
}

export default OurPartnerPage
