const IcCalendarFill = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 19 19"
    width={19}
    height={19}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M13.2778 1V4.4M5.72222 1V4.4M1 7.8H18M2.88889 2.7H16.1111C17.1543 2.7 18 3.46112 18 4.4V16.3C18 17.2389 17.1543 18 16.1111 18H2.88889C1.84568 18 1 17.2389 1 16.3V4.4C1 3.46112 1.84568 2.7 2.88889 2.7Z"
      stroke="#0D2542"
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default IcCalendarFill;
