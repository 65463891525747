export const faqData = [
    {
        title: "Réservations et fonctionnalités",
        questions: [
            {
                question: "Quel est le fonctionnement ?",
                answer: "Pour réserver un service, il vous suffit d'entrer vos critères sur notre plateforme. Que vous cherchiez un transport, un prestataire local ou un service à domicile, notre assistant dédié se charge de contacter le prestataire approprié. Vous pouvez également profiter d’une période d'essai gratuite avec l'assistance personnalisée pour organiser vos demandes.",
            },
            {
                contentInforBar:
                    "Une fois votre demande soumise, elle ne garantit pas une réservation immédiate. La confirmation dépend de l'acceptation par l'établissement (coiffeur, spa, etc.). Si la demande ne peut être honorée, nos concierges partenaires vous proposeront des alternatives validées.",
            },
            {
                question: "Comment je peux savoir que ma réservation est confirmée ?",
                answer: "Vous recevrez une confirmation par notification, SMS ou email avec tous les détails concernant votre service réservé.",
            },
            {
                question: "Que faire si je veux modifier ou annuler ma réservation ?",
                answer: "Pour toute modification ou annulation, il vous suffit de contacter directement votre assistant personnel via la plateforme. Il se chargera d'adapter ou de modifier votre réservation selon vos besoins.",
            },
            {
                question: "Quand dois-je régler ma facture ?",
                answer: "Le paiement se fait à la fin du service, selon les conditions établies avec le prestataire.",
            },
            {
                question: "Reserve-ici est-il un réseau de prestataires ?",
                answer: "Non, Reserve-ici n’est pas un réseau de prestataires. Nous facilitons la mise en relation entre nos clients et des prestataires de qualité, sélectionnés avec soin. Nous ne gérons pas directement les réservations, mais nous nous assurons que les services soient fournis avec professionnalisme. Nous ne sommes affiliés à aucun établissement.",
            },
        ],
    },
    {
        title: "Politiques et procédures",
        questions: [
            {
                question: "Comment fonctionne le processus de remboursement si j'annule ma réservation ?",
                answer: "Si vous annulez votre réservation, le remboursement sera traité rapidement. La garantie satisfait ou remboursé proposée par les conciergerie vous assure un remboursement efficace.",
            },
            {
                question: "Y a-t-il des frais cachés lors de la réservation ?",
                answer: "Non, tous les frais sont clairement affichés avant la validation de votre demande.",
            },
        ],
    },
    {
        title: "Service client et assistance",
        questions: [
            {
                question: "Comment contacter le service client et quels sont leurs horaires ?",
                answer: "Notre service client est disponible 7 jours sur 7, de 7h à 22h, par email ou directement via la plateforme. Votre assistant est également disponible pour répondre à toutes vos questions.",
            },
            {
                question: "Que faire si je dois annuler ma réservation ou si j'ai des problèmes avec l’établissement ?",
                answer: "En cas de problème, contactez directement votre assistant dédié via la plateforme. Il se chargera de trouver une solution ou de vous proposer des alternatives en cas d'annulation.",
            },
        ],
    },
]
