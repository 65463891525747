import React, { Suspense } from "react"
import FallbackLoading from "../FallbackLoading"

const OurMission = React.lazy(() => import("./OurMission.tsx"))

const OurMissionPage = () => {
    return (
        <Suspense fallback={<FallbackLoading />}>
            <OurMission />
        </Suspense>
    )
}

export default OurMissionPage
