import IcArrowDown from "./IcArrowDown.tsx"
import IcBag from "./IcBag.tsx"
import IcBus from "./IcBus.tsx"
import IcCalendar from "./IcCalendar.tsx"
import IcCalendarFill from "./IcCalendarFill.tsx"
import IcCar from "./IcCar.tsx"
import IcCb from "./IcCb.tsx"
import IcCheck from "./IcCheck.tsx"
import IcCheckBg from "./IcCheckBg.tsx"
import IcClock from "./IcClock.tsx"
import IcClose from "./IcClose.tsx"
import IcDiamond from "./IcDiamond.tsx"
import IcDownload from "./IcDownload.tsx"
import IcEmail from "./IcEmail.tsx"
import IcFork from "./IcFork.tsx"
import IcHeadPhone from "./IcHeadPhone.tsx"
import IcHourglass from "./IcHourglass.tsx"
import IcInfo from "./IcInfo.tsx"
import IcInfo2 from "./IcInfo2.tsx"
import IcListening from "./IcListening.tsx"
import IcLocation from "./IcLocation.tsx"
import IcLock2 from "./IcLock2.tsx"
import IcLockBase from "./IcLockBase.tsx"
import IcMaster from "./IcMaster.tsx"
import IcMenu from "./IcMenu.tsx"
import IcNote from "./IcNote.tsx"
import IcPercent from "./IcPercent.tsx"
import IcPhoneIncoming from "./IcPhoneIncoming.tsx"
import IcPlane from "./IcPlane.tsx"
import IcQuotationMarks from "./IcQuotationMarks.tsx"
import IcRealTime from "./IcRealTime.tsx"
import IcRespect from "./IcRespect.tsx"
import IcRestaurantFood from "./IcRestaurantFood.tsx"
import IcSchedule from "./IcSchedule.tsx"
import IcSearch from "./IcSearch.tsx"
import IcSearch2 from "./IcSearch2.tsx"
import IcSearch3 from "./IcSearch3.tsx"
import IcSearchDefault from "./IcSearchDefault.tsx"
import IcShield from "./IcShield.tsx"
import IcStar from "./IcStar.tsx"
import IcStarFill from "./IcStarFill.tsx"
import IcTrain from "./IcTrain.tsx"
import IcUser from "./IcUser.tsx"
import IcUserCheck from "./IcUserCheck.tsx"
import IcVisa from "./IcVisa.tsx"
import IcWhatsapp from "./IcWhatsapp.tsx"
import IcWoment from "./IcWoment.tsx"
import Iclock from "./Iclock.tsx"
import IcNext from "./IcNext.tsx"
import IcFlag from "./IcFlag.tsx"
import IcPolygon from "./IcPolygon.tsx"
import IcPhone from "./IcPhone.tsx"
import IcHoldingPhone from "./IcHoldingPhone.tsx"
import IcStarNotice from "./IcStarNotice.tsx"
import IcArrowUp from "./IcArrowUp.tsx"
import IcRestoResult from "./IcRestoResult.tsx"
import IcMessage from "./IcMessage.tsx"
import IcPhoneCall from "./IcPhoneCall.tsx"
import IcMap from "./IcMap.tsx"
import IcPhoneBasic from "./IcPhoneBasic.tsx"
import IcTaxi from "./IcTaxi.tsx"
import IcHotelColor from "./IcHotelColor.tsx"
import IcGroup from "./IcGroup.tsx"
import IcEdit from "./IcEdit.tsx"
import IcAlternativesBg from "./IcAlternativesBg.tsx"
import IcCanceledBg from "./IcCanceledBg.tsx"
import IcCardGrey from "./IcCardGrey.tsx"
import IcCBGrey from "./IcCBGrey.tsx"
import IcPCJdssGrey from "./IcPCJdssGrey.tsx"
import IcMastercardGrey from "./IcMastercardGrey.tsx"
import IcVisaGrey from "./IcVisaGrey.tsx"
import IcArrowForward from "./IcArrowForward.tsx"
import IcCustomerService from "./IcCustomerService.tsx"
import IcHighQuality from "./IcHighQuality.tsx"
import IcMail from "./IcMail.tsx"
import IcMobility from "./IcMobility.tsx"
import IcProvider from "./IcProvider.tsx"
import IcSandClock from "./IcSandClock.tsx"
import IcBrocheDeLocalisation from "./IcBrocheDeLocalisation.tsx"
import IcServiceADomicile from "./IcServiceADomicile.tsx"
import IcPencil from "./IcPencil.tsx"
import IcEstablishment from "./IcEstablishment.tsx"
import IcTransportation from "./IcTransportation.tsx"
import IcHourglassBasic from "./IcHourglassBasic.tsx"
import IcBowlOfNoodles from "./IcBowlOfNoodles.tsx"
import IcHairCut from "./IcHairCut.tsx"
import IcStethoscope from "./IcStethoscope.tsx"
import IcMassage from "./IcMassage.tsx"
import IcDumbbell from "./IcDumbbell.tsx"
import IcFleurDeLotus from "./IcFleurDeLotus.tsx"
import IcKeyAndNamePlate from "./IcKeyAndNamePlate.tsx"
import IcEssentialOil from "./IcEssentialOil.tsx"
import IcRelooking from "./IcRelooking.tsx"
import IcLivre from "./IcLivre.tsx"
import IcCleaning from "./IcCleaning.tsx"
import IcMother from "./IcMother.tsx"
import IcSelectionner from "./IcSelectionner.tsx"
import IcCycle from "./IcCycle.tsx"
import IcBell from "./IcBell.tsx"
import IcProfile from "./IcProfile.tsx"
import IcEuro from "./IcEuro.tsx"
import IcAutoBus from "./IcAutoBus.tsx"
import IcEye from "./IcEye.tsx"
import IcEyeSlash from "./IcEyeSlash.tsx"
import IcBill from "./IcBill.tsx"
import IcLogout from "./IcLogout.tsx"
import IcPhoneResto from "@/components/tailwind-components/atoms/Icons/IcPhoneResto.tsx"

export const iconList = {
    "ic-arrow-down": IcArrowDown,
    "ic-check": IcCheck,
    "ic-close": IcClose,
    "ic-download": IcDownload,
    "ic-email": IcEmail,
    "ic-search": IcSearch,
    "ic-diamond": IcDiamond,
    "ic-real-time": IcRealTime,
    "ic-schedule": IcSchedule,
    "ic-listening": IcListening,
    "ic-bag": IcBag,
    "ic-percent": IcPercent,
    "ic-location": IcLocation,
    "ic-head-phone": IcHeadPhone,
    "ic-shield": IcShield,
    "ic-search-2": IcSearch2,
    "ic-search-3": IcSearch3,
    "ic-woment": IcWoment,
    "ic-car": IcCar,
    "ic-note": IcNote,
    "ic-info": IcInfo,
    "ic-menu": IcMenu,
    "ic-lock": Iclock,
    "ic-respect": IcRespect,
    "ic-cb": IcCb,
    "ic-master": IcMaster,
    "ic-visa": IcVisa,
    "ic-lock-base": IcLockBase,
    "ic-calendar": IcCalendar,
    "ic-lock-2": IcLock2,
    "ic-whatsapp": IcWhatsapp,
    "ic-hourglass": IcHourglass,
    "ic-calendar-fill": IcCalendarFill,
    "ic-clock": IcClock,
    "ic-star": IcStar,
    "ic-user": IcUser,
    "ic-user-check": IcUserCheck,
    "ic-phone-incoming": IcPhoneIncoming,
    "ic-train": IcTrain,
    "ic-bus": IcBus,
    "ic-plane": IcPlane,
    "ic-info-2": IcInfo2,
    "ic-check-bg": IcCheckBg,
    "ic-search-default": IcSearchDefault,
    "ic-star-fill": IcStarFill,
    "ic-fork": IcFork,
    "ic-restaurant-food": IcRestaurantFood,
    "ic-quotation-marks": IcQuotationMarks,
    "ic-next": IcNext,
    "ic-flag": IcFlag,
    "ic-polygon": IcPolygon,
    "ic-phone": IcPhone,
    "ic-holding-phone": IcHoldingPhone,
    "ic-star-notice": IcStarNotice,
    "ic-arrow-up": IcArrowUp,
    "ic-resto-result": IcRestoResult,
    "ic-message": IcMessage,
    "ic-phone-call": IcPhoneCall,
    "ic-map": IcMap,
    "ic-phone-basic": IcPhoneBasic,
    "ic-taxi": IcTaxi,
    "ic-hotel-color": IcHotelColor,
    "ic-house-group": IcGroup,
    "ic-edit": IcEdit,
    "ic-alternatives-bg": IcAlternativesBg,
    "ic-canceled-bg": IcCanceledBg,
    "ic-Card-Grey": IcCardGrey,
    "ic-CB-Grey": IcCBGrey,
    "ic-PCJdss-Grey": IcPCJdssGrey,
    "ic-Mastercard-Grey": IcMastercardGrey,
    "ic-Visa-Grey": IcVisaGrey,
    "ic-Arrow-Forward": IcArrowForward,
    "ic-customer-service": IcCustomerService,
    "ic-high-quality": IcHighQuality,
    "ic-mail": IcMail,
    "ic-mobility": IcMobility,
    "ic-provider": IcProvider,
    "ic-sand-clock": IcSandClock,
    "ic-broche-de-localisation": IcBrocheDeLocalisation,
    "ic-service-a-domicile": IcServiceADomicile,
    "ic-pencil": IcPencil,
    "ic-establishment": IcEstablishment,
    "ic-transportation": IcTransportation,
    "ic-hourglass-basic": IcHourglassBasic,
    "ic-bowl-noodles": IcBowlOfNoodles,
    "ic-hair-cut": IcHairCut,
    "ic-stethoscope": IcStethoscope,
    "ic-massage": IcMassage,
    "ic-dumbbell": IcDumbbell,
    "ic-fleurdelotus": IcFleurDeLotus,
    "ic-key-and-nameplate": IcKeyAndNamePlate,
    "ic-essential-oil": IcEssentialOil,
    "ic-relooking": IcRelooking,
    "ic-livre": IcLivre,
    "ic-cleaning": IcCleaning,
    "ic-mother": IcMother,
    "ic-selectionner": IcSelectionner,
    "ic-cycle": IcCycle,
    "ic-bell": IcBell,
    "ic-profile": IcProfile,
    "ic-euro": IcEuro,
    "ic-auto-bus": IcAutoBus,
    "ic-eye": IcEye,
    "ic-eye-slash": IcEyeSlash,
    "ic-bill": IcBill,
    "ic-logout": IcLogout,
    "ic-phone-resto": IcPhoneResto,
}

export type IconType = keyof typeof iconList

export type Props = React.SVGProps<SVGSVGElement> & {
    name: IconType
}

const TwIcon = ({ name, ...props }: Props) => {
    const Result = iconList[name]
    return <Result {...props} />
}

export default TwIcon
