import { twMerge } from 'tailwind-merge';

const IcLock2 = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="20"
      viewBox="0 0 16 18"
      fill="none"
      {...props}
      className={twMerge('text-black', props.className)}
    >
      <path
        d="M7.32041 0.274969C5.3717 0.547132 3.68507 1.82227 2.74591 3.72994C2.32607 4.57667 2.04942 5.67288 2.01059 6.63806L2.00573 6.77666L1.31167 6.78422C0.641877 6.78926 0.615182 6.79178 0.5715 6.84218C0.525391 6.8951 0.525391 7.03118 0.525391 12.1746L0.525391 17.454L0.583634 17.5019C0.63945 17.5498 0.789912 17.5498 8.02418 17.5498C15.2585 17.5498 15.4089 17.5498 15.4647 17.5019L15.523 17.454L15.523 12.1721C15.523 7.13954 15.5205 6.89006 15.4793 6.84722C15.4405 6.8069 15.3652 6.79934 14.7391 6.78926L14.0426 6.77666L14.0378 6.63806C13.9456 4.34231 12.7419 2.18768 10.9266 1.06626C10.3199 0.690775 9.67197 0.443811 8.94636 0.310249C8.57506 0.242208 7.69414 0.22205 7.32041 0.274969ZM8.63088 3.32421C9.24728 3.46281 9.79574 3.77782 10.2447 4.24402C10.8514 4.87655 11.2081 5.68296 11.3004 6.6305L11.3149 6.78926L8.02418 6.78926L4.73345 6.78926L4.74801 6.6431C4.78199 6.32557 4.81353 6.13657 4.86935 5.91985C5.12902 4.92191 5.78182 4.0651 6.61907 3.62157C6.95882 3.44265 7.27187 3.33933 7.68443 3.27633C7.83004 3.25365 8.46586 3.28641 8.63088 3.32421ZM8.45858 9.44537C8.85657 9.63438 9.11866 10.0225 9.17691 10.5038C9.22787 10.9196 9.09925 11.3152 8.80561 11.6479L8.64301 11.8293L8.64301 13.2657L8.64301 14.7022L8.01205 14.7022L7.38108 14.7022L7.38108 13.2582L7.38108 11.8167L7.22819 11.6504C6.96367 11.3606 6.84719 11.0632 6.84719 10.6701C6.84719 10.2921 6.96125 9.98466 7.19664 9.72006C7.52911 9.34709 8.02903 9.23873 8.45858 9.44537Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default IcLock2;
