import { staticIciData } from "@/constants/local/ici"

export const iciCommon = {
    brandName: "Reserve-ici",
    data: staticIciData,
    pageName: {
        howItsWork: "Notre solution",
        ourMission: "Notre mission",
        ourPartner: "Votre assistant",
        faq: "FAQ",
    },
}
