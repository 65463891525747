const IcClock = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12.2009 10.5893L9.69117 8.70702V4.87281C9.69117 4.48728 9.37955 4.17566 8.99402 4.17566C8.6085 4.17566 8.29688 4.48728 8.29688 4.87281V9.05563C8.29688 9.27521 8.40006 9.48228 8.57573 9.61335L11.3643 11.7048C11.4897 11.7989 11.6362 11.8442 11.7818 11.8442C11.9945 11.8442 12.2036 11.7487 12.3403 11.5646C12.5718 11.2571 12.509 10.82 12.2009 10.5893Z"
      fill="#0D2542"
    />
    <path
      d="M9 0C4.0371 0 0 4.0371 0 9C0 13.9629 4.0371 18 9 18C13.9629 18 18 13.9629 18 9C18 4.0371 13.9629 0 9 0ZM9 16.6057C4.80674 16.6057 1.39426 13.1933 1.39426 9C1.39426 4.80674 4.80674 1.39426 9 1.39426C13.194 1.39426 16.6057 4.80674 16.6057 9C16.6057 13.1933 13.1933 16.6057 9 16.6057Z"
      fill="#0D2542"
    />
  </svg>
);

export default IcClock;
