import React, { Suspense } from "react"
import FallbackLoading from "../FallbackLoading"

const HowItsWork = React.lazy(() => import("./HowItsWork"))
const TwHowItsWork = React.lazy(() => import("./TwHowItsWork.tsx"))

const HowItsWorkPage = () => {
    return (
        <Suspense fallback={<FallbackLoading />}>
            {import.meta.env.VITE_VERTICALE !== "taxi" ? <TwHowItsWork /> : <HowItsWork />}
        </Suspense>
    )
}

export default HowItsWorkPage
