import { useState } from "react"
import {
    Box,
    BoxProps,
    Drawer,
    DrawerBody,
    DrawerCloseButton,
    DrawerContent,
    DrawerOverlay,
    Flex,
    Text,
    VStack,
} from "@chakra-ui/react"
import { Link } from "react-router-dom"
import { HamburgerIcon } from "@chakra-ui/icons"
import { SearchIcon } from "@/theme/icons/Icons"

type MenuProps = {
    linkColor: "white" | "black"
} & BoxProps
interface NavLink {
    name: string
    path: string
    component: (props: any) => JSX.Element
}

const links: NavLink[] = [
    {
        name: "Rechercher un Taxi",
        path: "/",
        component: ({ linkColor, ...rest }) => (
            <Flex alignItems="center" gap="8px">
                <SearchIcon height="28px" width="28px" color={"#707070"} />
                <Text {...rest}>Rechercher un Taxi</Text>
            </Flex>
        ),
    },
    {
        name: "A propos",
        path: "/a-propos",
        component: ({ linkColor, ...rest }) => <Text {...rest}>A propos</Text>,
    },
    {
        name: "Carrière",
        path: "/carriere",
        component: ({ linkColor, ...rest }) => <Text {...rest}>Carrière</Text>,
    },
    {
        name: "Nos engagements",
        path: "/engagements",
        component: ({ linkColor, ...rest }) => <Text {...rest}>Nos engagements</Text>,
    },
    // {
    //     name: "Contact",
    //     path: "/contact",
    //     component: ({ linkColor, ...rest }) => <Text {...rest}>Contact</Text>,
    // },
]

const Menu = ({ linkColor, ...rest }: MenuProps) => {
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false)
    const toggleMobileMenu = () => {
        setIsMobileMenuOpen(!isMobileMenuOpen)
    }

    return (
        <Box {...rest}>
            <Flex display={{ base: "none", lg: "flex" }} direction="row" align="center" gap="20px" h="100%">
                {links.map(({ path, name, component: Component }) => (
                    <Link key={name} to={path}>
                        <Component variant="base" whiteSpace="nowrap" color={linkColor} linkColor={linkColor} />
                    </Link>
                ))}
            </Flex>

            <Flex display={{ base: "flex", lg: "none" }} align="center" h="100%">
                <HamburgerIcon boxSize="22px" onClick={toggleMobileMenu} cursor="pointer" color={linkColor} />
                <Drawer isOpen={isMobileMenuOpen} placement="right" onClose={toggleMobileMenu} size="full">
                    <DrawerOverlay />
                    <DrawerContent>
                        <DrawerCloseButton
                            position="absolute"
                            top="20px"
                            right="20px"
                            sx={{
                                "& svg": {
                                    height: "18px",
                                    width: "18px",
                                },
                            }}
                        />
                        <DrawerBody>
                            <VStack
                                spacing="35px"
                                direction="column"
                                alignItems={"center"}
                                justifyContent={"center"}
                                h="100%"
                            >
                                {links.map(({ path, name, component: Component }) => (
                                    <Link key={name} to={path}>
                                        <Component
                                            fontSize="24px"
                                            _hover={{ textDecoration: "none" }}
                                            variant="base"
                                            linkColor={linkColor}
                                            onClick={toggleMobileMenu}
                                        />
                                    </Link>
                                ))}
                            </VStack>
                        </DrawerBody>
                    </DrawerContent>
                </Drawer>
            </Flex>
        </Box>
    )
}

export default Menu
