const IcEdit = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="16"
    height="19"
    viewBox="0 0 16 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M1 18.0002H14.6" stroke="black" strokeLinecap="round" />
    <path
      d="M13.1867 6.19459L13.8174 5.56559C14.0658 5.31728 14.2628 5.02249 14.3972 4.69804C14.5317 4.37359 14.6009 4.02584 14.6009 3.67464C14.601 3.32344 14.5318 2.97567 14.3975 2.65119C14.2631 2.32671 14.0661 2.03187 13.8178 1.78351C13.5695 1.53514 13.2747 1.33812 12.9503 1.20369C12.6258 1.06925 12.2781 1.00004 11.9269 1C11.5757 0.999961 11.2279 1.0691 10.9034 1.20346C10.579 1.33782 10.2841 1.53478 10.0358 1.78308L9.40505 2.41378C9.40505 2.41378 9.4841 3.75339 10.6656 4.93489C11.8471 6.11639 13.1867 6.19544 13.1867 6.19544L7.39224 11.9899C6.99954 12.3826 6.80404 12.5781 6.58729 12.7473C6.33172 12.9467 6.05717 13.1164 5.76364 13.2564C5.51629 13.3746 5.25279 13.4621 4.72664 13.6372L2.49453 14.3818M9.40505 2.41293L3.61058 8.20825C3.21788 8.60095 3.02153 8.79645 2.85323 9.0132C2.65386 9.26828 2.48312 9.54448 2.34408 9.83685C2.22593 10.0842 2.13838 10.3477 1.96243 10.8739L1.21868 13.106M1.21868 13.106L1.03763 13.6508C0.995188 13.7779 0.988985 13.9142 1.01972 14.0446C1.05045 14.1749 1.11689 14.2942 1.21161 14.3889C1.30632 14.4836 1.42555 14.55 1.55592 14.5808C1.68629 14.6115 1.82264 14.6053 1.94968 14.5629L2.49453 14.3818M1.21868 13.106L2.49453 14.3818"
      stroke="black"
    />
  </svg>
);

export default IcEdit;
