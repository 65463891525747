import { restoCommon } from "@/constants/common/restoCommon.ts"
import { iciCommon } from "@/constants/common/iciCommon.ts"

const datas = {
    taxi: restoCommon,
    resto: restoCommon,
    ici: iciCommon,
}

export const commonData = datas[import.meta.env.VITE_VERTICALE]
