import React, { Suspense } from "react"
import FallbackLoading from "../FallbackLoading"

const AboutUs = React.lazy(() => import("./AboutUs"))
const RestoAboutUs = React.lazy(() => import("./RestoAboutUs"))

const AboutUsPage = () => {
    return (
        <Suspense fallback={<FallbackLoading />}>
            {import.meta.env.VITE_VERTICALE === "resto" ? <RestoAboutUs /> : <AboutUs />}
        </Suspense>
    )
}

export default AboutUsPage
