import { twMerge } from 'tailwind-merge';

const IcCheck = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="11"
      height="8"
      viewBox="0 0 11 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      className={twMerge('text-gray-400', props.className)}
    >
      <path
        d="M10.3073 0.37793C10.6003 0.670898 10.6003 1.14668 10.3073 1.43965L4.30732 7.43965C4.01436 7.73262 3.53857 7.73262 3.24561 7.43965L0.245605 4.43965C-0.0473633 4.14668 -0.0473633 3.6709 0.245605 3.37793C0.538574 3.08496 1.01436 3.08496 1.30732 3.37793L3.77764 5.8459L9.24795 0.37793C9.54092 0.0849609 10.0167 0.0849609 10.3097 0.37793H10.3073Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default IcCheck;
