const IcBell = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="21"
    height="27"
    viewBox="0 0 21 27"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M0.167969 22.5443V19.9609H2.7513V10.9193C2.7513 9.13247 3.2895 7.55017 4.36589 6.1724C5.44228 4.77309 6.84158 3.85816 8.5638 3.4276V2.52344C8.5638 1.98524 8.74679 1.53316 9.11276 1.16719C9.50026 0.779687 9.96311 0.585938 10.5013 0.585938C11.0395 0.585938 11.4916 0.779687 11.8576 1.16719C12.2451 1.53316 12.4388 1.98524 12.4388 2.52344V3.4276C14.161 3.85816 15.5603 4.77309 16.6367 6.1724C17.7131 7.55017 18.2513 9.13247 18.2513 10.9193V19.9609H20.8346V22.5443H0.167969ZM10.5013 26.4193C9.79089 26.4193 9.17735 26.1717 8.66068 25.6766C8.16554 25.1599 7.91797 24.5464 7.91797 23.8359H13.0846C13.0846 24.5464 12.8263 25.1599 12.3096 25.6766C11.8145 26.1717 11.2117 26.4193 10.5013 26.4193ZM5.33464 19.9609H15.668V10.9193C15.668 9.49844 15.1621 8.28212 14.1503 7.27031C13.1385 6.25851 11.9221 5.7526 10.5013 5.7526C9.08047 5.7526 7.86415 6.25851 6.85234 7.27031C5.84054 8.28212 5.33464 9.49844 5.33464 10.9193V19.9609Z"
      fill="currentColor"
    />
  </svg>
);

export default IcBell;
