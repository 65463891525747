const IcCalendar = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 22 24"
    fill="none"
    width={22}
    height={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M6 3.44444H5.00025C3.60011 3.44444 2.89953 3.44444 2.36475 3.71088C1.89434 3.94523 1.51216 4.31891 1.27249 4.77887C1 5.30176 1 5.98678 1 7.3558V8.33333M6 3.44444H16M6 3.44444V1M1 8.33333V19.0891C1 20.4581 1 21.1423 1.27249 21.6652C1.51216 22.1251 1.89434 22.4995 2.36475 22.7338C2.899 23 3.59874 23 4.99614 23H17.0039C18.4013 23 19.1 23 19.6343 22.7338C20.1046 22.4995 20.4881 22.1251 20.7277 21.6652C21 21.1428 21 20.4596 21 19.0933V8.33333M1 8.33333H21M16 3.44444H17.0002C18.4004 3.44444 19.0995 3.44444 19.6343 3.71088C20.1046 3.94523 20.4881 4.31891 20.7277 4.77887C21 5.30124 21 5.98543 21 7.35178V8.33333M16 3.44444V1M16 18.1111H16.0025V18.1136H16V18.1111ZM11 18.1111H11.0025V18.1136H11V18.1111ZM6 18.1111H6.0025L6.00244 18.1136H6V18.1111ZM16.0025 13.2222V13.2247H16V13.2222H16.0025ZM11 13.2222H11.0025V13.2247H11V13.2222ZM6 13.2222H6.0025L6.00244 13.2247H6V13.2222Z"
      stroke="#616161"
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default IcCalendar;
