import { defineStyle, defineStyleConfig } from "@chakra-ui/react"

const baseStyle = defineStyle({
    borderRadius: "3px",
    backgroundColor: "white",
    color: "black",
})

const blueStyle = defineStyle({
    borderRadius: "2px",
    backgroundColor: "white",
    color: "black",
    border: "2px solid var(--chakra-colors-secondary)",
    _focus: {
        border: "2px solid var(--chakra-colors-secondary)",
    },
})

const variants = {
    base: baseStyle,
    blue: blueStyle,
}

// const md = defineStyle({
//     px: "14px",
//     h: { base: "32px", lg: "62px" },
//     fontSize: { base: "14px", lg: "16px" },
// })

const lg = defineStyle({
    fontSize: { base: "16px" },
    p: "16px",
    h: { base: "160px" },
})

// const xl = defineStyle({
//     px: "16px",
//     h: { base: "52px", lg: "62px" },
//     fontSize: "16px",
// })

export const textareaTheme = defineStyleConfig({
    variants: variants,
    sizes: { lg },
})
