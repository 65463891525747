import { defineStyle, defineStyleConfig } from "@chakra-ui/react"

const extrasm = defineStyle({
    color: "#000000",
    fontSize: { base: "10px", lg: "12px" },
})

const sm = defineStyle({
    color: "#000000",
    fontSize: { base: "12px", lg: "14px" },
})

const base = defineStyle({
    color: "#000000",
    fontSize: { base: "14px", lg: "16px" },
})

const lg = defineStyle({
    color: "#000000",
    fontSize: { base: "16px", lg: "18px" },
})

const xl = defineStyle({
    color: "#000000",
    fontSize: { base: "20px", lg: "30px" },
    fontWeight: 600,
    lineHeight: { base: "24px", lg: "36px" },
})

const variants = {
    extrasm,
    base,
    sm,
    lg,
    xl,
}

export const textTheme = defineStyleConfig({
    baseStyle: {
        mb: 0,
    },
    variants,
})
